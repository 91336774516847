import React from "react";
import { Box, Grid } from "@mui/material";
import theme from "../pages/theme";
import { ThemeProvider } from "@mui/material/styles";
import HeadingText from "../components/HeadingText";
import ChatBotGif from "../images/chat-bot.gif";

const FeatureExplainer = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <HeadingText title="Integrated ChatGPT Bot" />
        <Box
          sx={{
            padding: "0 100px 0 100px",
            "@media (max-width: 600px)": {
              padding: "0 0px 0 0px",
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 3 }}
            columns={{ xs: 1, sm: 1, md: 16, lg: 8 }}
          >
            <Grid item xs={1} sm={1} md={5} lg={4}>
              <Box sx={{ textAlign: "center", color: "#242c31" }}>
                <Box
                  sx={{
                    padding: "53px 53px 20px 53px",
                    fontSize: "19px",
                    fontWeight: "700",
                    "@media (max-width: 600px)": {
                      padding: "20px 20px 20px 20px",
                    },
                  }}
                >
                  No need to answer queries manually
                </Box>
                <Box
                  sx={{
                    padding: "10px 53px 20px 53px",
                    fontSize: "19px",
                    fontWeight: "700",
                    "@media (max-width: 600px)": {
                      padding: "0 20px 20px 20px",
                    },
                  }}
                >
                  AI ChatBot Support - Trained on your custom data
                </Box>
                <Box
                  sx={{
                    padding: "0 53px 30px 53px",
                    fontSize: "17px",
                    fontWeight: "600",
                    "@media (max-width: 600px)": {
                      padding: "0 20px 20px 20px",
                    },
                  }}
                >
                  Instantly resolve queries of Employees with our AI-powered
                  chatbot, providing quick answers and reducing HR load.
                </Box>

                <Box
                  sx={{
                    padding: "0 53px 0px 53px",
                    fontSize: "17px",
                    "@media (max-width: 600px)": {
                      padding: "0 20px 0 20px",
                    },
                  }}
                >
                  The AI-Powered Onboardix Chat Bot is your intelligent
                  companion for seamless onboarding and comprehensive employee
                  support. Trained on custom data, this advanced chat bot
                  harnesses the power of AI to deliver instant and accurate
                  responses to queries of candidates and employees. <br />
                  <br />
                  With the Onboardix Chat Bot, users can receive personalized
                  guidance, step-by-step instructions, and access resources to
                  navigate the onboarding process effectively. Available 24/7,
                  the chat bot learns from user interactions to continuously
                  improve its responses, providing an enhanced and intuitive
                  user experience. <br />
                  <br />
                  Simplify your onboarding journey and empower candidates and
                  employees with the AI-Powered Onboardix Chat Bot. It's an
                  indispensable tool that streamlines support, reduces HR
                  workload, and ensures a smooth transition for new hires and
                  ongoing employee engagement.
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sm={1} md={5} lg={4}>
              <Box
                sx={{
                  padding: "20px 0px 50px 100px",
                  "@media (max-width: 600px)": {
                    padding: "5% 30% 30px 12%",
                    "& img": {
                      height: "300px",
                    },
                  },
                }}
              >
                <img
                  src={ChatBotGif}
                  height="500px"
                  loading="lazy"
                  alt="chatbot"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default FeatureExplainer;
