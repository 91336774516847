import React from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import Authbg from "../images/authbg.jpg";
import Logo from "../images/logo.jpg";

const Login = () => {
  const validationSchema = Yup.object().shape({
    pwd: Yup.string().required("This field cannot be empty"),
    email: Yup.string()
      .required("This field cannot be empty")
      .email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const loginSubmit = (data) => {};

  return (
    <div>
      <Box>
        <Grid
          container
          spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          columns={{ xs: 1, sm: 1, md: 16, lg: 12 }}
        >
          <Grid item xs={1} sm={1} md={5} lg={7}>
            <div
              className="feature-img-div"
              style={{
                backgroundImage: `url(${Authbg})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                padding: "64px",
              }}
            >
              <Box
                sx={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "700",
                  paddingBottom: "15px",
                  paddingTop: "30vh",
                }}
              >
                Welcome to Onboardix.
              </Box>
              <Box sx={{ color: "#909090", paddingBottom: "30px" }}>
                Streamline your onboarding process and save valuable time with
                our powerful workflows. Welcome new employees and clients
                effortlessly with Artificial Intelligence solutions.
              </Box>
              <Box sx={{ color: "white" }}>
                Join 104+ forward-thinking companies:
              </Box>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={5} lg={5}>
            <Box
              sx={{
                bgcolor: "#5e44af",
                height: "70px",
                marginTop: "20vh",
              }}
            >
              <Box sx={{ paddingTop: "9px", paddingLeft: "40px" }}>
                <img src={Logo} height="50px" loading="lazy" alt="logo" />
              </Box>
            </Box>
            <Box sx={{ p: "40px", paddingTop: "3vh" }}>
              <Box
                sx={{
                  fontSize: "25px",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
              >
                Log In
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  paddingBottom: "30px",
                }}
              >
                <Box>Don't have an account?</Box>{" "}
                <Box>
                  {" "}
                  &nbsp;<Link to="">Register</Link>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingBottom: "30px",
                }}
              >
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email Address"
                  fullWidth
                  {...register("email")}
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                />
              </Box>
              <Box
                sx={{
                  paddingBottom: "30px",
                }}
              >
                <TextField
                  required
                  id="pwd"
                  name="pwd"
                  label="Password"
                  type="password"
                  fullWidth
                  {...register("pwd")}
                  error={errors.pwd ? true : false}
                  helperText={errors.pwd?.message}
                />
              </Box>
              <Box
                sx={{
                  paddingBottom: "30px",
                }}
              >
                <div className="loginbutton">
                  <Button
                    variant="outlined"
                    onClick={handleSubmit(loginSubmit)}
                  >
                    Continue
                  </Button>
                </div>
              </Box>
              <Box
                sx={{
                  paddingBottom: "30px",
                }}
              >
                <Link to="">Forgot Password?</Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Login;
