import React from "react";
import { Box, AppBar, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhonelinkRingOutlinedIcon from "@mui/icons-material/PhonelinkRingOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import theme from "../../pages/theme";
import { Link } from "react-router-dom";

const TopBar = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <AppBar
          component={"div"}
          position="fixed"
          sx={{
            bgcolor: "primary",
            minHeight: "35px",
            paddingTop: "9px",
            paddingLeft: "4.85%",
          }}
        >
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2, lg: 0 }}
            columns={{ xs: 2, sm: 14, md: 14, lg: 16 }}
          >
            <Grid item xs={1} sm={4} md={3} lg={2}>
              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <EmailOutlinedIcon sx={{ fontSize: "17px" }} />
                <Box sx={{ fontSize: "12px", paddingLeft: "5px" }}>
                  info@onboardix.com
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sm={4} md={3} lg={2}>
              <Box
                sx={{
                  fontSize: "12px",
                  paddingLeft: "5px",
                  "@media (max-width: 600px)": {
                    position: "absolute",
                    right: 0,
                    paddingRight: "5.5%",
                  },
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <PhonelinkRingOutlinedIcon sx={{ fontSize: "17px" }} />
                  <Box sx={{ fontSize: "12px", paddingLeft: "5px" }}>
                    +91 81759 70707
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sm={6} md={8} lg={12}>
              <Box sx={{ position: "absolute", right: 0, paddingRight: "5%" }}>
                <Link
                  to="https://twitter.com/onboardix85288 "
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <TwitterIcon
                    sx={{
                      fontSize: "18px",
                      marginRight: "18px",
                      "&:hover": { color: "secondary.main", cursor: "pointer" },
                    }}
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/onboardix_/"
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <InstagramIcon
                    sx={{
                      fontSize: "18px",
                      marginRight: "18px",
                      "&:hover": { color: "secondary.main", cursor: "pointer" },
                    }}
                  />
                </Link>
                <Link
                  to="https://www.facebook.com/profile.php?id=100093688447158"
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <FacebookIcon
                    sx={{
                      fontSize: "18px",
                      marginRight: "18px",
                      "&:hover": { color: "secondary.main", cursor: "pointer" },
                    }}
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/in/ashpak-shaikh-3ba8a4186/ "
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <LinkedInIcon
                    sx={{
                      fontSize: "18px",
                      "&:hover": { color: "secondary.main", cursor: "pointer" },
                    }}
                  />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </AppBar>
      </ThemeProvider>
    </>
  );
};

export default TopBar;
