import React from "react";
import { Box, Avatar } from "@mui/material";

const ContactCard = (props) => {
  const { icon: Icon } = props;
  return (
    <div className="contactcard">
      <Box
        sx={{
          bgcolor: "#6c53b8",
          borderRadius: "15px",
          minHeight: "10px",
          padding: "20px",
          color: "white",
          display: "flex",
          flexWrap: "wrap",
          "@media (max-width: 600px)": {},
        }}
      >
        <Avatar sx={{ bgcolor: "#9784D0", width: 50, height: 50 }}>
          <Icon sx={{ fontSize: "28px" }} />
        </Avatar>
        <Box sx={{ paddingLeft: "20px" }}>
          <Box
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              paddingBottom: "7px",
            }}
          >
            {props.title}
          </Box>
          <Box
            sx={{
              fontSize: "15px",
              "@media (max-width: 600px)": {
                fontSize: "12px",
              },
            }}
          >
            {props.details}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ContactCard;
