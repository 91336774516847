import React from "react";
import { Box, Grid } from "@mui/material";
import ReactPlayer from "react-player";
import VideoImg from "../images/thumb.webp";

const VidepComp = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            <ReactPlayer
              url="https://www.youtube.com/embed/iulDbLShvvU?si=TAcaeXQ6Gi2-Lkjq"
              light={VideoImg}
              className="react-player"
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              "@media (max-width: 600px)": {
                margin: "0px 20px 10px 20px",
              },
            }}
          >
            <ReactPlayer
              url="https://www.youtube.com/embed/iulDbLShvvU?si=TAcaeXQ6Gi2-Lkjq"
              light={VideoImg}
              className="react-player"
              width="340px"
              height="200px"
            />
          </Box>
        </Box>
        <Box sx={{ width: "600px" }}>
          <Box
            sx={{
              fontWeight: "800",
              marginLeft: "25px",
              marginTop: "30px",
              background: "-webkit-linear-gradient(#5e44af, #00AE92)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              "@media (max-width: 600px)": {
                margin: "20px 20px 0px 20px",
                fontSize: "24px",
                fontWeight: "700",
              },
            }}
          >
            <div className="makeh2">
              <h2>Demystifying Onboardix: How It Works Explained</h2>
              <h2>Experience Seamless Onboarding with Onboardix</h2>
            </div>
          </Box>
          <Box
            sx={{
              marginLeft: "25px",
              marginTop: "25px",
              "@media (max-width: 600px)": {
                margin: "0px 20px 0px 20px",
                marginTop: "25px",
                fontSize: "15px",
              },
            }}
          >
            Explore the features of Onboardix in our explainer video. See how
            our platform can streamline onboarding for employees, clients, and
            vendors with customizable workflows, interactive timelines, and
            seamless document integration. Watch now to learn more!
          </Box>
          <Box
            sx={{
              marginLeft: "25px",
              marginTop: "10px",
              "@media (max-width: 600px)": {
                margin: "0px 20px 0px 20px",
                marginTop: "10px",
                fontSize: "15px",
              },
            }}
          >
            Double Click On Video to Maximize the Screen
          </Box>
        </Box>

        {/* <Grid
          container
          spacing={{ xs: 0, sm: 0, md: 0, lg: 6 }}
          columns={{ xs: 1, sm: 1, md: 16, lg: 24 }}
        >
          <Grid item xs={1} sm={1} md={5} lg={3}></Grid>
          <Grid item xs={1} sm={1} md={5} lg={9}>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <ReactPlayer
                url="https://www.youtube.com/embed/iulDbLShvvU?si=TAcaeXQ6Gi2-Lkjq"
                light={VideoImg}
                className="react-player"
                width="100%"
                height="100%"
              />
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                "@media (max-width: 600px)": {
                  margin: "0px 20px 10px 20px",
                },
              }}
            >
              <ReactPlayer
                url="https://www.youtube.com/embed/iulDbLShvvU?si=TAcaeXQ6Gi2-Lkjq"
                light={VideoImg}
                className="react-player"
                width="340px"
                height="200px"
              />
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={5} lg={9}>
            <Box
              sx={{
                fontWeight: "800",
                marginLeft: "25px",
                marginTop: "45px",
                background: "-webkit-linear-gradient(#5e44af, #00AE92)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                "@media (max-width: 600px)": {
                  margin: "20px 20px 0px 20px",
                  fontSize: "24px",
                  fontWeight: "700",
                },
              }}
            >
              <div className="makeh2">
                <h2>Demystifying Onboardix: How It Works Explained</h2>
                <h2>Experience Seamless Onboarding with Onboardix</h2>
              </div>
            </Box>
            <Box
              sx={{
                marginLeft: "25px",
                marginTop: "25px",
                "@media (max-width: 600px)": {
                  margin: "0px 20px 0px 20px",
                  marginTop: "25px",
                  fontSize: "15px",
                },
              }}
            >
              Explore the features of Onboardix in our explainer video. See how
              our platform can streamline onboarding for employees, clients, and
              vendors with customizable workflows, interactive timelines, and
              seamless document integration. Watch now to learn more!
            </Box>
            <Box
              sx={{
                marginLeft: "25px",
                marginTop: "10px",
                "@media (max-width: 600px)": {
                  margin: "0px 20px 0px 20px",
                  marginTop: "10px",
                  fontSize: "15px",
                },
              }}
            >
              Double Click On Video to Maximize the Screen
            </Box>
          </Grid>
          <Grid item xs={1} sm={1} md={5} lg={3}></Grid>
        </Grid> */}
      </Box>
    </div>
  );
};

export default VidepComp;
