import React from "react";
import { Grid, Box } from "@mui/material";
import FAQ from "./FAQ";

const FAQList = () => {
  return (
    <div>
      <Grid
        container
        spacing={{ xs: 0, sm: 0, md: 0, lg: 10 }}
        columns={{ xs: 1, sm: 1, md: 1, lg: 16 }}
      >
        <Grid item xs={1} sm={1} md={1} lg={5}>
          <Box
            sx={{
              marginLeft: "40px",
              "@media (max-width: 1190px)": {
                marginLeft: "0px",
              },
            }}
          >
            <Box sx={{ fontSize: "35px" }}>Frequently Asked</Box>
            <Box sx={{ fontSize: "35px", fontWeight: "700" }}>Questions</Box>
            <Box
              sx={{ marginTop: "18px", marginBottom: "18px", color: "#6C757D" }}
            >
              Got questions? We've got answers! Here are our top FAQs to help
              demystify the magical world of our onboarding tool. Don't worry,
              no wands or potions required, just a touch of humor and some handy
              information to make your onboarding journey enchantingly smooth!
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={11}>
          <FAQ
            num="1"
            question="How does Onboardix improve the onboarding process?"
            answer="Onboardix streamlines the onboarding process by allowing HR professionals to create customized workflows for each role. This ensures that new employees have a clear roadmap of what to expect, access to relevant resources, and step-by-step guidance throughout their onboarding journey."
          />
          <FAQ
            num="2"
            question="Can Onboardix handle bulk onboarding for multiple employees?"
            answer="Absolutely! Onboardix is designed to handle multiple onboardings simultaneously. HR professionals can effortlessly onboard multiple employees at the same time, saving time and effort while ensuring a consistent and efficient onboarding experience for all."
          />
          <FAQ
            num="3"
            question="Is Onboardix user-friendly and easy to use for HR professionals?"
            answer="Absolutely! Onboardix is designed with a user-friendly interface and intuitive features to ensure a seamless experience for HR professionals. Our platform is easy to navigate, and our comprehensive onboarding tools are designed to simplify the process. We provide robust documentation and support to guide HR professionals every step of the way, making it a breeze to create workflows, manage onboardings, and access the necessary resources."
          />
          <FAQ
            num="4"
            question="Can Onboardix generate reports and analytics related to onboarding?"
            answer="Absolutely! Onboardix provides comprehensive reporting and analytics features. You can track metrics such as the number of employees onboarded, progress status, and engagement levels. These insights help HR professionals assess the effectiveness of their onboarding processes and make data-driven improvements."
          />
          <FAQ
            num="5"
            question="Is Onboardix suitable for small and large organizations alike?"
            answer="Yes, Onboardix caters to organizations of all sizes. Whether you're a small startup or a large enterprise, our tool can be tailored to meet your specific onboarding needs. We understand that each organization is unique, and our flexible features and customizable options ensure a seamless fit for businesses of any scale."
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQList;
