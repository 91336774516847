import React from "react";
import HeadingText from "./HeadingText";
import { Box, Grid, Button } from "@mui/material";
import Blog from "./Blog";
import BlogImage3 from "../images/blog-3.jpg";
import BlogImg1 from "../images/blog-1.jpg";
import BlogImg2 from "../images/offboarding-means.jpg";
import AuthorImg from "../images/author.jpg";
import { HashLink } from "react-router-hash-link";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

const BlogsList = () => {
  const navigate = useNavigate();

  return (
    <div className="blogslistmain">
      <HeadingText title="Recent Blog posts" />
      <Box sx={{ marginTop: "60px" }}>
        <Grid
          container
          spacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
          columns={{ xs: 1, sm: 1, md: 1, lg: 12 }}
        >
          <Grid item xs={1} sm={1} md={1} lg={4}>
            <HashLink to="/blogs/psychology-of-successful-onboarding-and-offboarding">
              <Blog
                category="HR Insights"
                title="The Psychology of Successful Onboarding and Offboarding"
                image={BlogImg1}
                avatar={AuthorImg}
                name="Ashpak Shaikh"
                date="Sept 9, 2023"
              />
            </HashLink>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={4}>
            <HashLink to="/blogs/what-offboarding-means">
              <Blog
                category="Offboarding"
                title="What offboarding means ? The Hidden Key to Business Growth"
                image={BlogImg2}
                avatar={AuthorImg}
                name="Ashpak Shaikh"
                date="Sept 16, 2023"
              />
            </HashLink>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={4}>
            <HashLink to="/blogs/saas-solutions-for-sme-onboarding-success">
              <Blog
                category="Insights"
                title="SaaS Solutions for SME Onboarding Success : HR Insights"
                image={BlogImage3}
                avatar={AuthorImg}
                name="Ashpak Shaikh"
                date="Sept 8, 2023"
              />
            </HashLink>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          float: "right",
        }}
      >
        <Button
          onClick={() => {
            navigate(`/explore-articles`);
          }}
          sx={{
            textTransform: "none",
            cursor: "pointer",
            height: "53px",
            width: "220px",
            fontSize: "15px",
          }}
        >
          Explore More Articles <EastIcon sx={{ marginLeft: "10px" }} />
        </Button>
      </Box>
    </div>
  );
};

export default BlogsList;
