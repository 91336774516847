import React from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../pages/theme";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const checkstyle = {
  fontSize: "20px",
  color: "#03AC13",
  marginRight: "5px",
};

const featurestyle = {
  display: "flex",
  flexWrap: "wrap",
  paddingBottom: "15px",
  fontWeight: "600",
};

const premiumfeaturestyle = {
  display: "flex",
  flexWrap: "wrap",
  paddingBottom: "21px",
  fontWeight: "600",
};

const Pricing = (props) => {
  const businessaiText =
    "Educate the AI chatbot with personalized data to enhance its capabilities. Employees can now have their inquiries promptly addressed. With the Business plan, you gain access to 1,000,000 tokens per month, where each 1,000 tokens equate to roughly 750 words.";
  const premiumaiText =
    "Educate the AI chatbot with personalized data to enhance its capabilities. Employees can now have their inquiries promptly addressed. With the Business plan, you gain access to 500,000 tokens per month, where each 1,000 tokens equate to roughly 750 words.";

  const handleClickOpen = () => {
    window.location.href = "https://app.onboardix.com";
  };

  if (props.type === "normal") {
    const { icon: Icon } = props;

    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            bgcolor: "#ECEFF1",
            minHeight: "100px",
            borderRadius: "15px",
            marginTop: "90px",
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "bluegreytext.main",
            "@media (max-width: 900px)": {
              marginTop: "40px",
            },
          }}
        >
          <Box
            sx={{
              paddingTop: "50px",
              paddingBottom: "30px",
              fontSize: "22px",
              fontWeight: "700",
              "@media (max-width: 900px)": {
                marginTop: "10px",
              },
            }}
          >
            {props.plan}
          </Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px", marginBottom: "20px" }}
          >
            <Grid item xs={3}>
              <div className="pricingcircle">
                <Icon sx={{ fontSize: "50px", color: "white" }} />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px", marginBottom: "10px" }}
          >
            <Grid item xs={3}>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box
                  sx={{
                    fontSize: "30px",
                    color: "#03AC13",
                  }}
                >
                  <MonetizationOnIcon />
                </Box>
                <Box
                  sx={{
                    paddingBottom: "30px",
                    fontSize: "50px",
                    fontWeight: "700",
                    color: "#03AC13",
                  }}
                >
                  {props.price}
                </Box>
                <Box
                  sx={{
                    paddingTop: "30px",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  &nbsp;/ Month
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <Grid item xs={3}>
              <Box sx={featurestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list1}</Box>
                {props.plan === "Business Plan" ? (
                  <Tooltip title={businessaiText} placement="top" arrow>
                    <HelpOutlineIcon
                      sx={{ fontSize: "15px", ml: "2px", color: "#5e44af" }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Box>
              <Box sx={featurestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list2}</Box>
              </Box>
              <Box sx={featurestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list3}</Box>
              </Box>
              {props.plan === "Free Plan" ? (
                <Box sx={featurestyle}>
                  <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                  <Box>
                    <del>{props.list4}</del>
                  </Box>
                </Box>
              ) : null}
              {props.plan === "Free Plan" ? (
                <Box sx={featurestyle}>
                  <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                  <Box>
                    <del>{props.list5}</del>
                  </Box>
                </Box>
              ) : null}
              {props.plan === "Business Plan" ? (
                <Box sx={featurestyle}>
                  <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                  <Box>{props.list4}</Box>
                </Box>
              ) : null}
              {props.plan === "Business Plan" ? (
                <Box sx={featurestyle}>
                  <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                  <Box>{props.list5}</Box>
                </Box>
              ) : null}
            </Grid>
          </Grid>

          <Box>
            <div className="pricingbutton">
              <Button onClick={handleClickOpen}>{props.buttontext}</Button>
            </div>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }

  if (props.type === "premium") {
    const { icon: Icon } = props;
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            bgcolor: "#242c31",
            minHeight: "170px",
            borderRadius: "15px",
            marginTop: "50px",
            borderColor: "primary.main",
            borderStyle: "solid",
            borderWidth: "3px",
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#fafffe",
            "@media (max-width: 900px)": {
              marginTop: "40px",
            },
          }}
        >
          <Box
            sx={{
              paddingTop: "50px",
              paddingBottom: "30px",
              fontSize: "25px",
              fontWeight: "800",
            }}
          >
            {props.plan}
          </Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px", marginBottom: "20px" }}
          >
            <Grid item xs={3}>
              <div className="pricingpremiumcircle">
                <Icon sx={{ fontSize: "80px", color: "white" }} />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px", marginBottom: "10px" }}
          >
            <Grid item xs={3}>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <Box
                  sx={{
                    fontSize: "30px",
                    color: "#03AC13",
                  }}
                >
                  <MonetizationOnIcon />
                </Box>
                <Box
                  sx={{
                    paddingBottom: "30px",
                    fontSize: "60px",
                    fontWeight: "700",
                    color: "#03AC13",
                  }}
                >
                  {props.price}
                </Box>
                <Box
                  sx={{
                    paddingTop: "40px",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  &nbsp;/ Month
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <Grid item xs={3}>
              <Box sx={premiumfeaturestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list1}</Box>
                <Tooltip title={premiumaiText} placement="top" arrow>
                  <HelpOutlineIcon
                    sx={{ fontSize: "15px", ml: "2px", color: "#5e44af" }}
                  />
                </Tooltip>
              </Box>
              <Box sx={premiumfeaturestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list2}</Box>
              </Box>
              <Box sx={premiumfeaturestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list3}</Box>
              </Box>
              <Box sx={premiumfeaturestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list4}</Box>
              </Box>
              <Box sx={premiumfeaturestyle}>
                <CheckCircleOutlineRoundedIcon sx={checkstyle} />
                <Box>{props.list5}</Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <div className="prepricingbutton">
              <Button sx={{ color: "#fafffe" }} onClick={handleClickOpen}>
                Start Free Trial
              </Button>
            </div>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
};

export default Pricing;
