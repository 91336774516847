import { useState, useEffect } from "react";
import AppCss from "../src/App.css";
import Home from "./pages/Home";
import { Box } from "@mui/material";
import Pagenotfound from "./pages/Pagenotfound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/EmpRetentionBlog";
import OnboardersBlog from "./pages/blogs/OnboardersBlog";
import OffboardingDefinition from "./pages/blogs/OffboardingDefinition";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Checkout from "./pages/Checkout";
import Policies from "./pages/Policies";
import Terms from "./pages/Terms";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import TagManager from "react-gtm-module";
import AllArticles from "./pages/AllArticles";
import WhatOffboardingMeans from "./pages/blogs/WhatOffboardingMeans";
import WorkforceRetention from "./pages/blogs/WorkforceRetention";
import OnboardingCareers from "./pages/blogs/OnboardingCareers";
import OnboardingQuestionnaires from "./pages/blogs/OnboardingQuestionnaires";
import BadCulture from "./pages/blogs/BadCulture";
import OnboardingOffboardingStrategies from "./pages/blogs/OnboardingOffboardingStrategies";
import RecruitmentSystems from "./pages/blogs/RecruitmentSystems";
import RecruitmentAgencies from "./pages/blogs/RecruitmentAgencies";
import Checklist from "./pages/Checklist";
import Survey from "./components/Survey";
const tagManagerArgs = {
  gtmId: "GTM-5G9WDGMT",
};

function App() {
  const [hasConsentValue, setHasConsentValue] = useState(false);

  useEffect(() => {
    setHasConsentValue(!!getCookieConsentValue());
    if (getCookieConsentValue() === "true") {
      loadGoogLeAnalytics();
    }
  }, []);

  function loadGoogLeAnalytics() {
    //ReactGA.initialize("G-H7TN5DR2TL");
    TagManager.initialize(tagManagerArgs);
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/explore-articles" element={<AllArticles />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/termsandandconditions" element={<Terms />} />
        <Route
          path="/blogs/psychology-of-successful-onboarding-and-offboarding"
          element={<Blog1 />}
        />
        <Route path="/blogs/power-of-smooth-onboarding" element={<Blog4 />} />
        <Route
          path="/blogs/offboarding-definition-reimagined"
          element={<OffboardingDefinition />}
        />
        <Route
          path="/blogs/the-power-of-onboarders"
          element={<OnboardersBlog />}
        />
        <Route path="/blogs/onboarding-best-practices" element={<Blog2 />} />
        <Route
          path="/blogs/saas-solutions-for-sme-onboarding-success"
          element={<Blog3 />}
        />
        <Route
          path="/blogs/what-offboarding-means"
          element={<WhatOffboardingMeans />}
        />
        <Route
          path="/blogs/ultimate-guide-to-workforce-retention"
          element={<WorkforceRetention />}
        />
        <Route
          path="/blogs/onboarding-careers-a-secret-sauce"
          element={<OnboardingCareers />}
        />
        <Route
          path="/blogs/onboarding-questionnaires-in-smes"
          element={<OnboardingQuestionnaires />}
        />
        OnboardingOffboardingStrategies
        <Route path="/blogs/bad-workplace-culture" element={<BadCulture />} />
        <Route
          path="/blogs/onboarding-offboarding-strategies"
          element={<OnboardingOffboardingStrategies />}
        />
        <Route
          path="/blogs/recruitment-management-systems"
          element={<RecruitmentSystems />}
        />
        <Route path="/onboarding-checklist-template" element={<Checklist />} />
        <Route
          path="/blogs/recruitment-agencies-for-marketing"
          element={<RecruitmentAgencies />}
        />
        <Route path="/survey" element={<Survey />} />
        <Route path="/*" element={<Pagenotfound />} />
      </Routes>
      <Box
        style={{
          zIndex: 1000,
          position: "absolute",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {!hasConsentValue && (
          <CookieConsent
            onAccept={loadGoogLeAnalytics}
            onDecline={() => {
              setHasConsentValue(true);
            }}
            location="bottom"
            buttonText="I Understand"
            cookieName="CookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{
              color: "#4e503b",
              fontSize: "15px",
              marginRight: "310px",
            }}
            expires={150}
          >
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              This website uses cookies to enhance the user experience. See our
              &nbsp;
              <a href="/policies">
                <Box style={{ color: "#00AE92" }}>privacy policy</Box>
              </a>
              &nbsp; for more!
            </Box>
          </CookieConsent>
        )}
      </Box>
    </BrowserRouter>
  );
}

export default App;
