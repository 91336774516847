import React, { useRef, useState } from "react";
import HeadingText from "./HeadingText";
import { Box, Grid, TextField, Button, Snackbar } from "@mui/material";
import theme from "../pages/theme";
import { ThemeProvider } from "@mui/material/styles";
import ContactCard from "./ContactCard";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import MuiAlert from "@mui/material/Alert";
import TwitterIcon from "@mui/icons-material/Twitter";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () => {
  const form = useRef();

  const [alertOpen, setAlertOpen] = useState(false);
  const [errorAlertOpen, seterrorAlertOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field cannot be empty"),
    email: Yup.string()
      .required("This field cannot be empty")
      .email("Email is invalid"),
    subject: Yup.string().required("This field cannot be empty"),
    message: Yup.string().required("This field cannot be empty"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const contactSubmit = (data) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setAlertOpen(true);
          form.current.reset();
        },
        (error) => {
          seterrorAlertOpen(true);
          form.current.reset();
        }
      );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Message sent successfully! We'll get back to you shortly.
          </Alert>
        </Snackbar>

        <Snackbar
          open={errorAlertOpen}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Something went wrong! Please try again later.
          </Alert>
        </Snackbar>

        <HeadingText title="Contact" />
        <Box
          sx={{
            textAlign: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          Please contact us to register for demo or for any other query!
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            "@media (max-width: 900px)": {
              paddingBottom: "40px",
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 1, sm: 1, md: 1, lg: 20 }}
          >
            <Grid item xs={1} sm={1} md={1} lg={7}>
              <Box
                sx={{
                  bgcolor: "primary.main",
                  minHeight: "100px",
                  borderBottomLeftRadius: "15px",
                  borderTopLeftRadius: "15px",
                  "@media (max-width: 1100px)": {
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                    borderBottomLeftRadius: "0px",
                  },
                }}
              >
                <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <Box sx={{ paddingTop: "20px" }}>
                    <ContactCard
                      title="Twitter"
                      details="@onboardix"
                      icon={TwitterIcon}
                    />
                  </Box>
                  <Box sx={{ paddingTop: "20px" }}>
                    <ContactCard
                      title="Email"
                      details="info@onboardix.com"
                      icon={EmailOutlinedIcon}
                    />
                  </Box>
                  <Box sx={{ paddingTop: "20px" }}>
                    <ContactCard
                      title="Call"
                      details="+91 81759 70707"
                      icon={PhoneIphoneOutlinedIcon}
                    />
                  </Box>
                  <Box sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <ContactCard
                      title="Website"
                      details="www.onboardix.com"
                      icon={ComputerOutlinedIcon}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={13}>
              <Box
                sx={{
                  minHeight: "400px",
                  bgcolor: "#EEEEFF",
                  padding: "30px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  "@media (max-width: 1100px)": {
                    minHeight: "400px",
                    borderTopRightRadius: "0px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "15px",
                  },
                }}
              >
                <form ref={form}>
                  <Grid
                    container
                    spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}
                    height="350px"
                    sx={{
                      "@media (max-width: 1200px)": {
                        minHeight: "450px",
                      },
                    }}
                  >
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                      <TextField
                        required
                        id="username"
                        name="name"
                        label="Your Name"
                        fullWidth
                        {...register("name")}
                        error={errors.name ? true : false}
                        helperText={errors.name?.message}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                      <TextField
                        required
                        id="useremail"
                        label="Your Email"
                        name="email"
                        fullWidth
                        {...register("email")}
                        error={errors.email ? true : false}
                        helperText={errors.email?.message}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={2}>
                      <TextField
                        fullWidth
                        required
                        id="subject"
                        name="subject"
                        label="Subject"
                        {...register("subject")}
                        error={errors.subject ? true : false}
                        helperText={errors.subject?.message}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={2}>
                      <TextField
                        required
                        id="message"
                        name="message"
                        label="Message"
                        multiline
                        rows={4}
                        fullWidth
                        {...register("message")}
                        error={errors.message ? true : false}
                        helperText={errors.message?.message}
                      />
                    </Grid>
                  </Grid>
                </form>
                <div className="contactbutton">
                  <Button
                    variant="outlined"
                    onClick={handleSubmit(contactSubmit)}
                  >
                    Send Message
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default Contact;
