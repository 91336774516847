import React from "react";
import DashboardPng from "../images/dashboard.png";
import { Box } from "@mui/material";
import HeadingText from "./HeadingText";

const DashboardImg = () => {
  return (
    <div>
      <HeadingText title="HR/Admin Portal" />
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          paddingTop: "50px",
        }}
      >
        <img
          src={DashboardPng}
          width="85%"
          loading="lazy"
          alt="HR/Admin Portal"
        />
      </Box>
    </div>
  );
};

export default DashboardImg;
