import React from "react";
import { Box, Grid, Button } from "@mui/material";
import { HashLink } from "react-router-hash-link";

const MainBanner = () => {
  return (
    <div>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {/* <<<<<<<<<<<<< desktop view starts here >>>>>>>>>>>>>>  */}
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: "45px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#242c31",
              fontWeight: "700",
              padding: "0 400px 0 400px",
              marginTop: "130px",
              "@media (max-width: 1459px)": {
                padding: "0 350px 0 350px",
                h1: {
                  fontSize: "40px",
                },
              },
              "@media (max-width: 1413px)": {
                padding: "0 350px 0 350px",
                h1: {
                  fontSize: "35px",
                },
              },
              "@media (max-width: 1313px)": {
                padding: "0 330px 0 330px",
              },
              "@media (max-width: 1273px)": {
                padding: "0 320px 0 320px",
              },
              "@media (max-width: 1253px)": {
                padding: "0 300px 0 300px",
              },
              "@media (max-width: 1233px)": {
                padding: "0 280px 0 280px",
              },
              "@media (max-width: 1173px)": {
                padding: "0 260px 0 260px",
              },
              "@media (max-width: 1133px)": {
                padding: "0 240px 0 240px",
              },
              "@media (max-width: 600px)": {
                marginTop: "100px",
                padding: "0 20px 0 20px",
              },
            }}
          >
            &nbsp;&nbsp;
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "26px",
                },
              }}
            >
              <div className="makeh1">
                <h1>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Box sx={{ marginRight: "20px" }}>Enable </Box>
                    <div className="realistic-marker-highlight ">
                      Self-Onboarding
                    </div>{" "}
                    <Box sx={{ marginLeft: "20px" }}>for Your</Box>
                    <Box>
                      Employees, Clients and Vendors with AI Assistance!
                    </Box>
                  </Box>
                </h1>
              </div>
            </Box>
          </Box>
        </Box>
        {/* <<<<<<<<<<<<< mobile view starts here >>>>>>>>>>>>>>  */}
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: "45px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#242c31",
              fontWeight: "700",
              padding: "0 400px 0 400px",
              marginTop: "130px",
              "@media (max-width: 600px)": {
                fontSize: "25px",
                marginTop: "100px",
                padding: "0 20px 0 20px",
              },
            }}
          >
            &nbsp;&nbsp;
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "26px",
                },
              }}
            >
              <div className="makeh1">
                <h1>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Box sx={{ marginRight: "10px" }}>Enable </Box>
                    <div className="realistic-marker-highlight ">
                      Self-Onboarding
                    </div>{" "}
                    <Box>
                      for Your Employees, Clients and Vendors with AI
                      Assistance!
                    </Box>
                  </Box>
                </h1>
              </div>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              marginTop: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              fontWeight: "600",
              fontSize: "17px",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              padding: "0 450px 0 450px",

              "@media (max-width: 1413px)": {
                padding: "0 350px 0 350px",
              },
              "@media (max-width: 1313px)": {
                padding: "0 330px 0 330px",
              },
              "@media (max-width: 1273px)": {
                padding: "0 320px 0 320px",
              },
              "@media (max-width: 1253px)": {
                padding: "0 300px 0 300px",
              },
              "@media (max-width: 1233px)": {
                padding: "0 280px 0 280px",
              },
              "@media (max-width: 1173px)": {
                padding: "0 260px 0 260px",
              },
              "@media (max-width: 1133px)": {
                padding: "0 240px 0 240px",
              },
              "@media (max-width: 600px)": {
                fontSize: "15px",
                padding: "0 20px 0 20px",
              },
            }}
          >
            <div className="makeh3">
              <h3>
                Minimize Confusion, Delays, and Missed Growth Opportunities. Cut
                Onboarding related tasks by 70% using our tailored workflows and
                AI Chatbot trained on custom data.
              </h3>
            </div>
          </Box>
          <div className="bannergrid1">
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                marginBottom: "100px",
                "@media (max-width: 600px)": {
                  marginBottom: "60px",
                },
              }}
            >
              <HashLink to="https://app.onboardix.com">
                <Button>Get Started</Button>
              </HashLink>
            </Box>
          </div>
        </Box>
      </Box>

      <svg
        xmlns="//www.w3.org/2000/svg"
        version="1.1"
        class="svg-filters"
        style={{ display: "none" }}
      >
        <defs>
          <filter id="marker-shape">
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0 0.15"
              numOctaves="1"
              result="warp"
            />
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="30"
              in="SourceGraphic"
              in2="warp"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default MainBanner;

// import React from "react";
// import { Box, Grid, Button } from "@mui/material";
// import Banner from "../images/banner-img.svg";
// import theme from "../pages/theme";
// import { ThemeProvider } from "@mui/material/styles";
// import { HashLink } from "react-router-hash-link";

// const MainBanner = () => {
//   return (
//     <>
//       <ThemeProvider theme={theme}>
//         <Box sx={{ display: { xs: "none", sm: "block" } }}>
//           <div className="mainbanner">
//             <Box sx={{ bgcolor: "primary" }}>
//               <Grid container>
//                 <Grid item xs={6}>
//                   <div className="bannergrid1">
//                     <h1>
//                       Enable Self-Onboarding for Your Employees with AI
//                       Assistance!
//                     </h1>
//                     {/* <div className="wrapper">
//                       <div className="words">
//                         <span>Enhanced</span>
//                         <span>Perfected</span>
//                         <span>AI Driven</span>
//                         <span>Proficient</span>
//                         <span>Enhanced</span>
//                       </div>
//                       <p>Onboarding</p>
//                     </div> */}
//                     <p>
//                       Cut HR workload by 70% using our tailored workflows and AI
//                       Chatbot trained on custom data.
//                     </p>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         flexWrap: "wrap",
//                       }}
//                     >
//                       <HashLink to="https://app.onboardix.com">
//                         <Button>Get Started</Button>
//                       </HashLink>
//                     </Box>
//                   </div>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <div className="bannergrid2">
//                     <img loading="lazy" src={Banner} alt="banner" />
//                   </div>
//                 </Grid>
//               </Grid>
//             </Box>
//             <section>
//               <div className="wave wave1"></div>
//               <div className="wave wave2"></div>
//               <div className="wave wave3"></div>
//               <div className="wave wave4"></div>
//             </section>
//           </div>
//         </Box>

//         {/* <<<<<<<<<<<<< mobile view starts here >>>>>>>>>>>>>>  */}
//         <Box sx={{ display: { xs: "block", sm: "none" } }}>
//           <div className="mobilebanner">
//             <Box sx={{ bgcolor: "#5e44af" }}>
//               <Grid
//                 container
//                 direction="column"
//                 alignItems="center"
//                 justifyContent="center"
//               >
//                 <Grid item xs={12}>
//                   <div className="mobilebannergrid2">
//                     <img loading="lazy" src={Banner} alt="banner" />
//                   </div>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <div className="mobilebannergrid1">
//                     <div className="mobilewrapper">
//                       <p>
//                         Enable Self-Onboarding for Your Employees with AI
//                         Assistance!
//                       </p>
//                     </div>
//                     <div className="mobiletagline">
//                       <p>
//                         Cut HR workload by 70% using our tailored workflows and
//                         AI Chatbot trained on custom data.
//                       </p>
//                     </div>
//                     <HashLink to="/#pricing">
//                       <Button>Get Started</Button>
//                     </HashLink>
//                   </div>
//                 </Grid>
//               </Grid>
//             </Box>
//             <section>
//               <div className="wave wave1"></div>
//               <div className="wave wave2"></div>
//               <div className="wave wave3"></div>
//               <div className="wave wave4"></div>
//             </section>
//           </div>
//         </Box>
//       </ThemeProvider>
//     </>
//   );
// };

// export default MainBanner;
