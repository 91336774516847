import React from "react";
import HeadingText from "./HeadingText";
import ServiceCard from "../components/ServiceCard";
import { Box, Grid } from "@mui/material";
import LanRoundedIcon from "@mui/icons-material/LanRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import ViewTimelineRoundedIcon from "@mui/icons-material/ViewTimelineRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";

const Services = () => {
  return (
    <div className="servicemain">
      <HeadingText title="AI Powered Solutions" />
      <Box
        sx={{
          marginTop: "70px",
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, sm: 3, md: 3, lg: 3.5 }}
          columns={{ xs: 1, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item xs={1} sm={12} md={6} lg={4}>
            <ServiceCard
              title="Effortless Onboarding Processes"
              icon={LanRoundedIcon}
              desc="Create customized workflows for each role, ensuring a seamless onboarding experience with clear next steps and document links for employees."
            />
          </Grid>
          <Grid item xs={1} sm={12} md={6} lg={4}>
            <ServiceCard
              title="Transparent Onboarding Journey"
              icon={ViewTimelineRoundedIcon}
              desc="Provide employees with a comprehensive timeline that outlines their onboarding progress, guiding them through the process and eliminating confusion."
            />
          </Grid>
          <Grid item xs={1} sm={12} md={6} lg={4}>
            <ServiceCard
              title="Efficient Mass Onboarding"
              icon={GroupsRoundedIcon}
              desc="HR professionals can easily onboard multiple employees simultaneously, saving time and effort while ensuring a consistent onboarding experience."
            />
          </Grid>
          <Grid item xs={1} sm={12} md={6} lg={4}>
            <ServiceCard
              title="Timely Communication Delivered"
              icon={NotificationsActiveRoundedIcon}
              desc="Automatically send reminders to candidates, ensuring they stay informed about upcoming tasks and deadlines during the onboarding journey."
            />
          </Grid>
          <Grid item xs={1} sm={12} md={6} lg={4}>
            <ServiceCard
              title="Data-Driven Insights"
              icon={AssessmentRoundedIcon}
              desc="Generate detailed reports on employee onboarding metrics and analytics, gaining valuable insights to optimize processes and track progress."
            />
          </Grid>
          <Grid item xs={1} sm={12} md={6} lg={4}>
            <ServiceCard
              title="Customized Experiences"
              icon={DashboardCustomizeRoundedIcon}
              desc="Create customized portals for each employee, providing relevant resources and tasks specific to their role and onboarding journey, ensuring a personalized and engaging onboarding experience."
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Services;
