import React from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import Layout from "../components/Layout/Layout";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const subHeadingStyle = {
    fontSize: "18px",
    pt: "40px",
    fontWeight: "600",
  };

  const paraStyle = {
    fontSize: "16px",
    pt: "20px",
  };

  return (
    <Layout>
      <Box
        sx={{
          padding: "140px 75px 100px 75px",
        }}
      >
        <Box sx={{ fontSize: "22px", fontWeight: "700" }}>
          Terms and conditions
        </Box>
        <Box sx={{ paddingTop: "25px", fontWeight: "500" }}>
          Last updated: 13th Aug, 2023
        </Box>
        <Box sx={{ pt: "25px", fontSize: "16px" }}>
          Welcome to Onboardix! These terms and conditions outline the rules and
          regulations for using our SaaS tool. By accessing and using Onboardix,
          you accept and agree to these terms. Please read them carefully before
          proceeding.
        </Box>
        <Box sx={subHeadingStyle}>Introduction:</Box>
        <Box sx={paraStyle}>
          Onboardix is here to redefine onboarding for HR professionals and new
          employees in the IT industry. We've leveraged cutting-edge technology
          to create an experience that's engaging, efficient, and impactful.
        </Box>
        <Box sx={subHeadingStyle}>The Challenge:</Box>
        <Box sx={paraStyle}>
          Traditional onboarding often leaves new employees bewildered and
          disconnected. Onboardix is the solution that clears the fog, providing
          clarity, guidance, and unwavering support.
        </Box>
        <Box sx={subHeadingStyle}>The Solution:</Box>
        <Box sx={paraStyle}>
          Dedicated HR Portal : Our dedicated portal empowers HR professionals.
          Craft custom workflows, add employee onboardings, download reports,
          manage reminders, and utilize an all-inclusive dashboard. Engaging
          Candidate Portal : For candidates, we've designed a portal that makes
          onboarding effortless. Log in, access a personalized timeline and
          status, step-by-step guidance within documents curated by HRs, provide
          feedback, and enjoy a seamless onboarding experience.
        </Box>
        <Box sx={subHeadingStyle}>User Accounts:</Box>
        <Box sx={paraStyle}>
          Free Plan - $0: Up to 10 FREE onboardings FREE 2 Role-based Workflows
          Limited Reporting and Analytics. Premium Plan - $79/month (Monthly) /
          $69/month (Annual): AI-Powered Personalized Chat Bot (200,000
          words/month) 2,000 onboardings and 20 Workflows Unlimited Reporting
          and Analytics Unlimited Mass/Bulk onboarding Unlimited Automatic
          reminders. Business Plan - $149/month (Monthly) / $139/month (Annual):
          AI-Powered Personalized Chat Bot (400,000 words/month - practically
          unlimited) 10,000 onboardings and 40 Workflows Unlimited Reporting and
          Analytics Unlimited Mass/Bulk onboarding Unlimited Automatic reminders
        </Box>
        <Box sx={subHeadingStyle}>User Actions and Responsibilities:</Box>
        <Box sx={paraStyle}>
          HRs can create workflows, add employee onboardings, track status, and
          manage reports. Employees can log in, access timelines, detailed
          onboarding documents, provide feedback, and enjoy a seamless
          experience.
        </Box>

        <Box sx={subHeadingStyle}>Account Registration:</Box>
        <Box sx={paraStyle}>
          During registration, users need to provide their name and email.
          Organization name is optional.
        </Box>
        <Box sx={subHeadingStyle}>User Responsibilities:</Box>
        <Box sx={paraStyle}>
          Users must provide accurate information and not engage in activities
          that violate our terms.
        </Box>
        <Box sx={subHeadingStyle}>Prohibited Activities:</Box>
        <Box sx={paraStyle}>
          Engaging in hacking, data manipulation, or any activity compromising
          the platform's integrity is strictly prohibited.
        </Box>
        <Box sx={subHeadingStyle}>
          Creating Workflows and Onboarding Processes:
        </Box>
        <Box sx={paraStyle}>
          Creating workflows is easy. Log in, access the Workflows tab, name the
          workflow, add steps, and document links. Adding onboardings involves
          providing candidate details and selecting workflows.
        </Box>
        <Box sx={subHeadingStyle}>Security Measures:</Box>
        <Box sx={paraStyle}>
          Our platform uses trusted authentication providers and database
          systems to prioritize data protection.
        </Box>
        <Box sx={subHeadingStyle}>Payment Process:</Box>
        <Box sx={paraStyle}>
          Choose a plan, sign up for a 14-day free trial, and then proceed with
          payment to continue the chosen plan.
        </Box>
        <Box sx={subHeadingStyle}>
          Service Interruptions and Technical Issues:
        </Box>
        <Box sx={paraStyle}>
          We aim to resolve service interruptions and technical issues within 48
          hours.
        </Box>
        <Box sx={subHeadingStyle}>Account Termination:</Box>
        <Box sx={paraStyle}>
          Upon account termination request, all data is deleted within 48 hours.
        </Box>
        <Box sx={subHeadingStyle}>Ownership of Data:</Box>
        <Box sx={paraStyle}>
          Users solely own their input data. We only share data with Auth0 and
          MongoDB for authentication and storage.
        </Box>
        <Box sx={subHeadingStyle}>Customer Support:</Box>
        <Box sx={paraStyle}>
          Access customer support through articles and queries from the "Get
          Help" tab. A contact number is also available.
        </Box>
        <Box sx={subHeadingStyle}>Warranties and Guarantees:</Box>
        <Box sx={paraStyle}>
          We provide no guarantees; users can evaluate services with a 14-day
          trial before purchasing.
        </Box>
        <Box sx={subHeadingStyle}>Consequences of Violating Terms:</Box>
        <Box sx={paraStyle}>Violations may result in account termination.</Box>
        <Box sx={subHeadingStyle}>Refund and Cancellation Policy:</Box>
        <Box sx={paraStyle}>
          Plans can be cancelled anytime. Due to the nature of the product, no
          refunds are available.
        </Box>
        <Box sx={subHeadingStyle}>Age Restriction:</Box>
        <Box sx={paraStyle}>There is no age restriction for users.</Box>
      </Box>
    </Layout>
  );
};

export default Terms;
