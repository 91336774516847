import React from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import Layout from "../components/Layout/Layout";

const Policies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const subHeadingStyle = {
    fontSize: "18px",
    pt: "40px",
    fontWeight: "600",
  };

  const paraStyle = {
    fontSize: "16px",
    pt: "20px",
  };

  return (
    <Layout>
      <Box
        sx={{
          padding: "140px 75px 100px 75px",
        }}
      >
        <Box sx={{ fontSize: "22px", fontWeight: "700" }}>
          Privacy Policy for Onboardix
        </Box>
        <Box sx={{ paddingTop: "25px", fontWeight: "500" }}>
          Last updated: 13th Aug, 2023
        </Box>
        <Box sx={{ pt: "25px", fontSize: "16px" }}>
          This Privacy Policy outlines how Onboardix ("we," "our," or "us")
          collects, uses, and safeguards the personal information of users
          ("you," "user," or "users") of our SaaS tool, Onboardix. We are
          committed to protecting your privacy and ensuring the security of your
          data. By accessing and using Onboardix, you consent to the practices
          described in this Privacy Policy.
        </Box>
        <Box sx={subHeadingStyle}>Information We Collect</Box>
        <Box sx={paraStyle}>
          We collect the following personal information from users of Onboardix:
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Organization name (optional)</li>
          </ul>{" "}
          When you create an onboarding, you may also provide the following
          information about employees:
          <ul>
            <li>Name</li>
            <li>Email address (mandatory)</li>
            <li>Secondary email address (optional)</li>
          </ul>{" "}
        </Box>
        <Box sx={subHeadingStyle}>How We Collect Information</Box>
        <Box sx={paraStyle}>
          We collect personal information through user registration and contact
          forms on our website. This information is voluntarily provided by you.
        </Box>
        <Box sx={subHeadingStyle}>Use of Collected Information</Box>
        <Box sx={paraStyle}>
          We use your personal information for the following purposes:
          <ul>
            <li>
              Communication: We use your email address to send you important
              updates, notifications, and information related to your Onboardix
              account.
            </li>
            <li>
              Onboarding: The information you provide about employees is used to
              facilitate the onboarding process as per your instructions.
            </li>
            <li>
              Reports: We generate reports containing employee email addresses
              for your use. These reports are for your internal purposes only
              and are not used for direct analytics.
            </li>
          </ul>{" "}
        </Box>
        <Box sx={subHeadingStyle}>Third-Party Disclosure</Box>
        <Box sx={paraStyle}>
          <Box>
            Payment Processing: We provide necessary user data to a third-party
            payment vendor to process payments for our services. This includes
            name, email address, and payment details.
          </Box>{" "}
          <Box>
            Data Storage: User data is securely stored in MongoDB, a reputable
            third-party data storage provider, ensuring the safety of your
            information.
          </Box>
        </Box>
        <Box sx={subHeadingStyle}>Security Measures</Box>
        <Box sx={paraStyle}>
          We take your data security seriously. We utilize MongoDB, a reputable
          third-party data storage provider, to store user data securely.
        </Box>
        <Box sx={subHeadingStyle}>Cookies and Tracking Technologies</Box>
        <Box sx={paraStyle}>
          Onboardix does not use cookies or tracking technologies. However, our
          authentication provider, Auth0, and payment interface provider may use
          cookies.
        </Box>
        <Box sx={subHeadingStyle}>User Consent</Box>
        <Box sx={paraStyle}>
          Your use of Onboardix constitutes your consent to the collection,
          processing, and use of your personal information as described in this
          Privacy Policy.
        </Box>
        <Box sx={subHeadingStyle}>Access, Update, and Deletion</Box>
        <Box sx={paraStyle}>
          Users can request the deletion of their data and account deactivation.
          Please allow up to 48 hours for data removal.
        </Box>
        <Box sx={subHeadingStyle}>Data Retention</Box>
        <Box sx={paraStyle}>
          We retain user data as long as your account is active. Upon request,
          we delete your data within 48 hours.
        </Box>
        <Box sx={subHeadingStyle}>Opt-Out Mechanisms</Box>
        <Box sx={paraStyle}>
          Users can choose not to provide optional information, such as
          organization name.
        </Box>
        <Box sx={subHeadingStyle}>Compliance with Regulations</Box>
        <Box sx={paraStyle}>
          We are committed to complying with data protection regulations such as
          GDPR and CCPA. We are in the process of finalizing our policies and
          procedures to ensure compliance.
        </Box>
        <Box sx={subHeadingStyle}>Age Restriction</Box>
        <Box sx={paraStyle}>
          Onboardix is intended for HR professionals who are 18 years or older.
          No age verification is required.
        </Box>
        <Box sx={subHeadingStyle}>How We Collect Information</Box>
        <Box sx={paraStyle}>
          We collect personal information through user registration and contact
          forms on our website. This information is voluntarily provided by you.
        </Box>
        <Box sx={subHeadingStyle}>Data Breach Notification</Box>
        <Box sx={paraStyle}>
          In the event of a data breach, we will promptly notify affected users
          via email.
        </Box>
      </Box>
    </Layout>
  );
};

export default Policies;
