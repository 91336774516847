import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Avatar,
} from "@mui/material";

const Blog = (props) => {
  return (
    <div>
      <Box>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              height="280"
              image={props.image}
              alt="blog-image"
            />
            <CardContent>
              <Typography
                gutterBottom
                sx={{
                  fontSize: "14px",
                  marginTop: "12px",
                  fontFamily: "Montserrat",
                }}
              >
                {props.category}
              </Typography>
              <Typography
                gutterBottom
                component="div"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  marginTop: "12px",
                  fontFamily: "Montserrat",
                }}
              >
                {props.title}
              </Typography>
              <Box
                sx={{ marginTop: "12px", display: "flex", flexWrap: "wrap" }}
              >
                <Avatar
                  alt="blogger"
                  src={props.avatar}
                  sx={{ width: 56, height: 56, bgcolor: "#291887" }}
                />
                <Box sx={{ marginLeft: "13px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      marginTop: "8px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {props.name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px", fontFamily: "Montserrat" }}
                  >
                    {props.date}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginTop: "15px" }}>
                <Typography sx={{ fontSize: "15px", fontFamily: "Montserrat" }}>
                  Read more...
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </div>
  );
};

export default Blog;
