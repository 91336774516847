import React from "react";
import { Box, Button } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import UpgradeImg from "../images/upgrade.jpg";

const BlogCTA = () => {
  return (
    <div>
      <Box
        sx={{
          bgcolor: "#f6f6f6",
          padding: "30px",
          borderRadius: "18px",
          marginTop: "20px",
          border: "dashed 2px #5e44af",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Box
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                paddingBottom: "10px",
              }}
            >
              Warning! Could You Be Harming Employee Retention?
            </Box>
            <Box sx={{ fontWeight: "600", fontSize: "18px" }}>
              Try Onboardix : Create Onboarding Experiences That Encourage
              Long-Term Employee Commitment
            </Box>
            <Box sx={{ fontSize: "14px", marginTop: "10px" }}>
              Streamline Your Onboarding Process with our tech-friendly,
              cost-effective solution. Guarantee seamless onboarding, retain
              talent, and scale your Organization effortlessly.
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <HashLink to="https://app.onboardix.com" target="blank">
                <Button
                  sx={{
                    backgroundColor: "#5e44af",
                    color: "white",
                    fontSize: "13px",
                    fontWeight: "bold",
                    padding: "8px 25px",
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    marginTop: "10px",
                    "&:hover": { color: "#5e44af" },
                  }}
                >
                  Start Your Free Trial Now!
                </Button>
              </HashLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BlogCTA;
