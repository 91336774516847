import React from "react";
import { Box, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../pages/theme";
import { Link } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Logo from "../../images/logo.jpg";
import { HashLink } from "react-router-hash-link";

const footericonstyle = {
  marginRight: "24px",
  "&:hover": { color: "secondary.main", cursor: "pointer" },
};

const Footer = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="footerlargescreen">
        <Box
          sx={{
            marginTop: "100px",
            bgcolor: "primary.main",
            minHeight: "100px",
            width: "92.1%",
            color: "white",
            paddingLeft: "68px",
            paddingRight: "72px",
          }}
          media="screen and (min-device-width: 900px)"
        >
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3, lg: 6 }}
            columns={{ xs: 1, sm: 12, md: 12, lg: 20 }}
          >
            <Grid item xs={1} sm={6} md={5} lg={9}>
              <Box>
                <HashLink to="/#home">
                  <img
                    loading="lazy"
                    src={Logo}
                    alt="logo"
                    height="47"
                    width="230"
                  />
                </HashLink>
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                  width: "85%",
                  fontWeight: "600",
                  paddingTop: "15px",
                }}
              >
                Streamline your onboarding process and save valuable time with
                our powerful workflows. Welcome new employees and clients
                effortlessly.
              </Box>
              <Box sx={{ paddingTop: "24px" }}>
                <Link
                  to="https://twitter.com/onboardix85288 "
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <TwitterIcon sx={footericonstyle} />
                </Link>
                <Link
                  to="https://www.instagram.com/onboardix_/"
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <InstagramIcon sx={footericonstyle} />
                </Link>
                <Link
                  to="https://www.facebook.com/profile.php?id=100093688447158"
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <FacebookIcon sx={footericonstyle} />
                </Link>
                <Link
                  to="https://www.linkedin.com/in/ashpak-shaikh-3ba8a4186/ "
                  target="_blank"
                  style={{ color: "#FFF" }}
                >
                  <LinkedInIcon sx={footericonstyle} />
                </Link>
              </Box>
            </Grid>
            <Grid item xs={1} sm={6} md={2} lg={3}>
              <Box>
                <Box>
                  <h3>Useful Links</h3>
                  <ul className="footerlinks">
                    <li>
                      <HashLink to="/#home">Home</HashLink>
                    </li>
                    <li>
                      <HashLink to="/#pricing">Pricing</HashLink>
                    </li>
                    <li>
                      <HashLink to="/#about">About us</HashLink>
                    </li>
                    <li>
                      <HashLink to="/policies">Privacy Policy</HashLink>
                    </li>
                    <li>
                      <HashLink to="/termsandandconditions">
                        Terms and Conditions
                      </HashLink>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sm={6} md={2} lg={4}>
              <Box>
                <Box>
                  <h3>Our Capabilities</h3>
                  <ul className="footerlinks">
                    <li>
                      <NavLink to={""}>
                        Streamlined Onboarding Workflows
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>Interactive Employee Timelines</NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>Bulk Onboarding Efficiency</NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>
                        Comprehensive Reporting and Analytics
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>
                        Automated Reminders and Notifications
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>Personalized Onboarding Portals</NavLink>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={1} sm={6} md={3} lg={4}>
              <Box>
                <Box>
                  <h3>Contact Us</h3>
                </Box>
                <Box sx={{ fontSize: "14px", fontWeight: "600" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: "10px",
                    }}
                  >
                    <Box sx={{ fontWeight: "600", paddingRight: "5px" }}>
                      Phone:
                    </Box>
                    +91 81759 70707
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      paddingTop: "24px",
                    }}
                  >
                    <Box sx={{ fontWeight: "600", paddingRight: "5px" }}>
                      Email:
                    </Box>
                    info@onboardix.com
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              paddingTop: "50px",
              paddingBottom: "30px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            © Copyright Onboardix. All Rights Reserved <br /> Designed by Ash
          </Box>
        </Box>
      </div>
      <div className="footersmallscreen">
        <Box
          sx={{
            bgcolor: "primary.main",
            minHeight: "50px",
            width: "90%",
            color: "white",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <Box
            sx={{
              paddingTop: "45px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <TwitterIcon sx={{ paddingRight: "24px" }} />
            <InstagramIcon sx={{ paddingRight: "24px" }} />
            <FacebookIcon sx={{ paddingRight: "24px" }} />
            <LinkedInIcon sx={{ paddingRight: "24px" }} />
          </Box>
          <Box
            sx={{
              paddingTop: "30px",
              paddingBottom: "45px",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "15px",
            }}
          >
            © Copyright Onboardix. All Rights Reserved <br /> Designed by Ash
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Footer;
