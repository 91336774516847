import React from "react";
import UsrDashPng from "../images/usr-dash.png";
import { Box } from "@mui/material";
import HeadingText from "./HeadingText";

const UserDash = () => {
  return (
    <div>
      <HeadingText title="Candidate Portal" />
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "-55px",
          "@media (max-width: 600px)": {
            marginTop: "0px",
          },
        }}
      >
        <img
          src={UsrDashPng}
          width="90%"
          loading="lazy"
          alt="Candidate Portal"
        />
      </Box>
    </div>
  );
};

export default UserDash;
