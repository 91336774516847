import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../pages/theme";
import { ThemeProvider } from "@mui/material/styles";

const FAQ = (props) => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ marginBottom: "25px" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                sx={{
                  width: "3%",
                  flexShrink: 0,
                  fontWeight: "800",
                  color: "primary.main",
                  fontSize: "18px",
                  "@media (max-width: 600px)": {
                    width: "7%",
                  },
                }}
              >
                {props.num}.
              </Box>
              <Box
                sx={{
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {props.question}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ color: "#212529" }}>{props.answer}</Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default FAQ;
