import React, { useState } from "react";
import HeadingText from "./HeadingText";
import Pricing from "./Pricing";
import { Box, Grid } from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";
import BusinessIcon from "@mui/icons-material/Business";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import { HashLink } from "react-router-hash-link";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const PricingList = () => {
  const [period, setPeriod] = useState(false);

  const handleChange = () => {
    setPeriod(!period);
  };
  return (
    <div className="pricingmain">
      <HeadingText title="Pricing" />
      <Box
        sx={{
          textAlign: "center",
          justifyContent: "center",
          marginTop: "35px",
          fontSize: "17px",
          fontWeight: "500",
        }}
      >
        Please <HashLink to="/#contact">Contact Us</HashLink> for customised
        plans!
      </Box>
      <Box
        sx={{
          textAlign: "center",
          justifyContent: "center",
          marginTop: "5px",
          fontSize: "17px",
          fontWeight: "500",
        }}
      >
        No credit cards required for Free Trial!
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          justifyContent: "center",
          textAlign: "center",
          "@media (max-width: 600px)": {
            fontSize: "16px",
          },
        }}
      >
        <FormControlLabel
          control={
            <IOSSwitch
              sx={{ marginLeft: "30px" }}
              defaultChecked
              onChange={handleChange}
            />
          }
        />
        Pay Annually (Save upto 20%)
      </Box>
      <Box
        sx={{
          padding: "0 100px 0 100px",
          "@media (max-width: 600px)": {
            padding: "0 0px 0 0px",
          },
        }}
      >
        <Grid
          container
          spacing={{ xs: 0, sm: 0, md: 0, lg: 8 }}
          columns={{ xs: 1, sm: 1, md: 16, lg: 16 }}
        >
          <Grid item xs={1} sm={1} md={5} lg={5}>
            <Pricing
              type="normal"
              plan="Free Plan"
              icon={RedeemIcon}
              price="0"
              list1="Up to 10 FREE onboarding"
              list2="FREE 2 Role based Workflows"
              list3="Limited Reporting and Analytics"
              list4="Mass/Bulk onboarding"
              list5="Automatic reminders"
              buttontext="Sign Up Now"
            />
          </Grid>
          <Grid item xs={1} sm={1} md={6} lg={6}>
            <Pricing
              type="premium"
              plan="Premium Plan"
              icon={WorkspacePremiumRoundedIcon}
              price={period ? "79" : "69"}
              list1="Personalised ChatGPT Bot"
              list2="2k onboarding &#38; 30 Workflows"
              list3="Unlimted Reporting and Analytics"
              list4="Unlimited Mass/Bulk onboarding"
              list5="Unlimited Automatic reminders"
            />
          </Grid>
          <Grid item xs={1} sm={1} md={5} lg={5}>
            <Pricing
              type="normal"
              plan="Business Plan"
              icon={BusinessIcon}
              price={period ? "149" : "139"}
              list1="Personalised ChatGPT Bot"
              list2="10k onboarding &#38; 40 Workflows"
              list3="Unlimted Reporting and Analytics"
              list4="Unlimited Mass/Bulk onboarding"
              list5="Unlimited Automatic reminders"
              buttontext="Start Free Trial"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PricingList;
