import React from "react";
import { Box, Divider } from "@mui/material";
import BlogComp from "../components/BlogComp";
import ChecklistImg from "../images/checklist.jpg";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import BlogCTA from "../components/BlogCTA";

const RecruitmentAgencies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Box>
      <div className="application">
        <Helmet>
          <title>Onboarding Checklist Template</title>

          <meta name="description" content="" data-react-helmet="true" />
        </Helmet>
      </div>
      <BlogComp
        title="Onboarding Checklist Template"
        author="Ashpak Shaikh"
        date="8th Oct, 2023"
        image={ChecklistImg}
        imgalt="recruitment management systems"
      >
        <h3>Pre-boarding Stage:</h3>
        <ul>
          <ol>
            <li>
              Send Welcome Email with Onboarding Details
              <ul>
                <li>Include date, time, and location for the first day</li>
              </ul>
              <ul>
                <li>Attach any pre-reading materials, if applicable</li>
              </ul>
            </li>

            <li>
              Prepare Workspace
              <ul>
                <li>Ensure desk, computer, and supplies are ready</li>
              </ul>
              <ul>
                <li>Provide necessary stationery and equipment</li>
              </ul>
            </li>

            <li>
              Set Up Accounts
              <ul>
                <li>
                  Create login credentials for company email, software, and
                  tools
                </li>
              </ul>
              <ul>
                <li>Verify access to relevant systems and platforms</li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Orientation Stage:</h3>
        <ul>
          <ol>
            <li>
              Introduce Company Policies
              <ul>
                <li>Provide an employee handbook with detailed policies</li>
              </ul>
              <ul>
                <li>
                  Schedule a session to go over key policies and answer
                  questions
                </li>
              </ul>
            </li>

            <li>
              Meet the Team
              <ul>
                <li>
                  Arrange introductions with immediate team members and
                  supervisors
                </li>
              </ul>
              <ul>
                <li>
                  Facilitate meetings with cross-functional teams for broader
                  integration
                </li>
              </ul>
            </li>

            <li>
              Tour the Office
              <ul>
                <li>
                  Familiarize the new employee with key facilities (restrooms,
                  break areas, kitchen, etc.)
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  Highlight emergency exits, first aid kits, and other safety
                  measures
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Training Stage:</h3>
        <ul>
          <ol>
            <li>
              Technical Training
              <ul>
                <li>Provide access to necessary software and tools</li>
              </ul>
              <ul>
                <li>Arrange training sessions or tutorials for each tool</li>
              </ul>
            </li>

            <li>
              Role-Specific Training
              <ul>
                <li>
                  Tailor training sessions to the employee's specific job
                  function
                </li>
              </ul>
              <ul>
                <li>Assign a mentor or trainer for ongoing support</li>
              </ul>
            </li>

            <li>
              Safety and Security Briefing
              <ul>
                <li>
                  Review emergency procedures, evacuation routes, and assembly
                  points
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  Explain security protocols for access, visitors, and sensitive
                  information
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Integration Stage:</h3>
        <ul>
          <ol>
            <li>
              Set Short-term Goals
              <ul>
                <li>
                  Collaborate with the employee to define achievable goals for
                  the first 30 days
                </li>
              </ul>
              <ul>
                <li>Provide clear metrics or criteria for success</li>
              </ul>
            </li>

            <li>
              Schedule Check-in Meetings
              <ul>
                <li>
                  Establish regular check-in meetings to provide feedback and
                  address concerns
                </li>
              </ul>
              <ul>
                <li>Encourage open communication for ongoing support</li>
              </ul>
            </li>

            <li>
              Provide Resources
              <ul>
                <li>
                  Share relevant documents, templates, and guides related to the
                  role
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  Direct the employee to internal knowledge repositories or
                  resources
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Training Stage:</h3>
        <ul>
          <ol>
            <li>
              Technical Training
              <ul>
                <li>Provide access to necessary software and tools</li>
              </ul>
              <ul>
                <li>Arrange training sessions or tutorials for each tool</li>
              </ul>
            </li>

            <li>
              Role-Specific Training
              <ul>
                <li>
                  Tailor training sessions to the employee's specific job
                  function
                </li>
              </ul>
              <ul>
                <li>Assign a mentor or trainer for ongoing support</li>
              </ul>
            </li>

            <li>
              Safety and Security Briefing
              <ul>
                <li>
                  Review emergency procedures, evacuation routes, and assembly
                  points
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  Explain security protocols for access, visitors, and sensitive
                  information
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Documentation Stage:</h3>
        <ul>
          <ol>
            <li>
              Complete HR Paperwork
              <ul>
                <li>
                  Provide necessary forms (tax, benefits, direct deposit, etc.)
                </li>
              </ul>
              <ul>
                <li>
                  Assist with filling out forms and ensure all required
                  information is provided
                </li>
              </ul>
            </li>

            <li>
              Verify Credentials and Certifications
              <ul>
                <li>
                  Confirm all necessary qualifications and certifications are on
                  file
                </li>
              </ul>
              <ul>
                <li>
                  Coordinate with HR or relevant departments for verification
                </li>
              </ul>
            </li>

            <li>
              Set Up Payroll and Benefits
              <ul>
                <li>
                  Guide the employee through the process of setting up direct
                  deposit and benefits enrollment
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  Ensure all necessary paperwork is submitted to HR or the
                  appropriate department
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Cultural Assimilation Stage:</h3>
        <ul>
          <ol>
            <li>
              Encourage Team Bonding
              <ul>
                <li>
                  Plan team-building activities, lunches, or informal gatherings
                </li>
              </ul>
              <ul>
                <li>
                  Foster a sense of camaraderie and inclusivity within the team
                </li>
              </ul>
            </li>

            <li>
              Communicate Company Values
              <ul>
                <li>
                  Share the company's mission, vision, and values, and explain
                  how they align with the employee's role
                </li>
              </ul>
              <ul>
                <li>
                  Encourage discussions around the company culture and values
                </li>
              </ul>
            </li>

            <li>
              Foster Open Communication
              <ul>
                <li>
                  Create an environment where questions, feedback, and
                  suggestions are welcomed and valued
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  Ensure the employee knows how to reach out for support or
                  guidance
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <h3>Feedback and Evaluation Stage:</h3>
        <ul>
          <ol>
            <li>
              Conduct 30-Day Review
              <ul>
                <li>
                  Schedule a performance review meeting to evaluate progress and
                  discuss any concerns
                </li>
              </ul>
              <ul>
                <li>
                  Provide constructive feedback and set clear expectations for
                  the upcoming period
                </li>
              </ul>
            </li>

            <li>
              Plan for the Future
              <ul>
                <li>
                  Discuss potential career development opportunities within the
                  company
                </li>
              </ul>
              <ul>
                <li>
                  Identify areas for growth and create a roadmap for the
                  employee's professional development
                </li>
              </ul>
            </li>
          </ol>
        </ul>
        {/*  */}
        <Divider />
        <Divider />
        <p>
          <b>
            All these stages can be made simpler with the help of Onboardix.
            Please review the details below for more information.
          </b>
        </p>
        <BlogCTA />
        <p>
          If you need further help with onboardig and offboarding then we have
          something for you, meet Onboardix, your solution to flawless
          onboarding! A seamless onboarding experience leaves a lasting
          impression on your employees and boosts their loyalty. With Onboardix,
          we've got you covered:
        </p>
        <p>
          ✨ AI Chatbot: Instantly answer employee queries using customized
          data.
        </p>
        <p> 📋 Custom Workflows: Tailor onboarding processes for each role.</p>{" "}
        <p>👥 Employee Portal: Provide a dedicated space for your team.</p>
        <p>🕒 Clear Timeline: Guide employees through each onboarding step. </p>
        <p>
          Discover Onboardix now at{" "}
          <a href="https://onboardix.com">https://onboardix.com!</a>
        </p>
      </BlogComp>
    </Box>
  );
};

export default RecruitmentAgencies;
