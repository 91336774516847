import React from "react";
import Layout from "../components/Layout/Layout";
import { Box, Grid } from "@mui/material";
import NotFound from "../images/404.svg";

const Pagenotfound = () => {
  return (
    <>
      <Layout>
        <Grid
          container
          spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          columns={{ xs: 10, sm: 3, md: 3, lg: 10 }}
        >
          <Grid item xs={1} sm={1} md={1} lg={3}></Grid>
          <Grid item xs={8} sm={1} md={1} lg={4}>
            <div className="notfound">
              <Box
                sx={{
                  fontSize: "22px",
                  fontWeight: "500",
                  marginBottom: "40px",
                }}
              >
                404: Page not found. This is not the page you're looking for,
                but we promise it's not in a galaxy far, far away!
              </Box>
              <img loading="lazy" src={NotFound} alt="About us" />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={3}></Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default Pagenotfound;
