import React, { useState } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import Authbg from "../images/authbg.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Register = () => {
  const [verified, setVerified] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field cannot be empty"),
    lastname: Yup.string().required("This field cannot be empty"),
    org: Yup.string().required("This field cannot be empty"),
    pwd: Yup.string().required("This field cannot be empty"),
    reenterpwd: Yup.string()
      .required("This field cannot be empty")
      .oneOf([Yup.ref("pwd")], "Passwords do not match."),
    email: Yup.string()
      .required("This field cannot be empty")
      .email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const registerSubmit = (data) => {
    console.log(data);
    axios
      .post(`http://localhost:8000/api/createuser`, data)
      .then((res) => {
        console.log(res);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyUser = () => {
    setVerified(true);
  };

  return (
    <div>
      <Box>
        <Grid
          container
          spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          columns={{ xs: 1, sm: 1, md: 16, lg: 12 }}
        >
          <Grid item xs={1} sm={1} md={5} lg={7}>
            <div
              className="feature-img-div"
              style={{
                backgroundImage: `url(${Authbg})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                padding: "64px",
              }}
            >
              <Box
                sx={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "700",
                  paddingBottom: "15px",
                  paddingTop: "30vh",
                }}
              >
                Sign Up - Onboardix
              </Box>
              <Box sx={{ color: "#909090", paddingBottom: "30px" }}>
                Streamline your onboarding process and save valuable time with
                our powerful workflows. Welcome new employees and clients
                effortlessly with Artificial Intelligence solutions.
              </Box>
              <Box sx={{ color: "white" }}>
                Join 104+ forward-thinking companies:
              </Box>
            </div>
          </Grid>

          <Grid item xs={1} sm={1} md={5} lg={5}>
            <Box>
              <Box sx={{ p: "40px", paddingTop: "4vh" }}>
                <Box
                  sx={{
                    fontSize: "25px",
                    fontWeight: "600",
                    paddingBottom: "6px",
                  }}
                >
                  Register
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingBottom: "20px",
                  }}
                >
                  <Box>Already Have An Account?</Box>{" "}
                  <Box>
                    {" "}
                    &nbsp;<Link to="">Log-in</Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="First Name"
                    fullWidth
                    {...register("name")}
                    error={errors.name ? true : false}
                    helperText={errors.name?.message}
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label="Last Name"
                    fullWidth
                    {...register("lastname")}
                    error={errors.lastname ? true : false}
                    helperText={errors.lastname?.message}
                  />
                </Box>

                <Box
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email Address"
                    fullWidth
                    {...register("email")}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <TextField
                    required
                    id="org"
                    name="org"
                    label="Organisation"
                    fullWidth
                    {...register("org")}
                    error={errors.org ? true : false}
                    helperText={errors.org?.message}
                  />
                </Box>

                <Box
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <TextField
                    required
                    id="pwd"
                    name="pwd"
                    label="Password"
                    type="password"
                    fullWidth
                    {...register("pwd")}
                    error={errors.pwd ? true : false}
                    helperText={errors.pwd?.message}
                  />
                </Box>
                <Box
                  sx={{
                    paddingBottom: "20px",
                  }}
                >
                  <TextField
                    required
                    id="reenterpwd"
                    name="reenterpwd"
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    {...register("reenterpwd")}
                    error={errors.reenterpwd ? true : false}
                    helperText={errors.reenterpwd?.message}
                  />
                </Box>
                <Box>
                  <Box>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHE_SITE_KEY}
                      onChange={verifyUser}
                    />
                  </Box>
                  <div className="loginbutton">
                    <Button
                      variant="outlined"
                      onClick={handleSubmit(registerSubmit)}
                      disabled={!verified}
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Register;
