import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5E44AF",
    },
    secondary: {
      main: "#00AE92",
    },
    bluegreytext: {
      main: "#36454F",
    },
  },
});

export default theme;
