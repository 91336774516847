import React from "react";
import { Box, Button, Grid } from "@mui/material";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";
import { HashLink } from "react-router-hash-link";

const CallToAction = () => {
  return (
    <div>
      <Box>
        <div className="calltoaction">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "10px" }}
          >
            <Grid item xs={3}>
              <Box id="rocketicon">
                <RocketLaunchRoundedIcon sx={{ fontSize: "50px" }} />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ fontSize: "30px", fontWeight: "700" }}>
            Supercharge Your Onboarding with AI Fuelled Capabilities!{" "}
          </Box>
          <p>
            Take the leap and revolutionize your onboarding process. Get started
            now and experience the power of seamless automation!
          </p>
          <HashLink to="/#contact">
            <Button variant="outlined">Request Demo!</Button>
          </HashLink>
        </div>
      </Box>
    </div>
  );
};

export default CallToAction;
