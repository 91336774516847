import React from "react";
import { Box, Button } from "@mui/material";
import { HashLink } from "react-router-hash-link";

const CtaComp = (props) => {
  return (
    <div>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            padding: "30px",
            background: "linear-gradient(to right bottom, #5e44af, #00AE92)",
            margin: "0 200px 0 200px",
            borderRadius: "18px",
            display: "flex",
            justifyContent: "center",
            "@media (max-width: 600px)": {
              margin: "0 20px 0 20px",
              display: "flow",
            },
          }}
        >
          <Box
            sx={{
              color: "#fafffe",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "8px",
            }}
          >
            {props.message}
          </Box>
          <Box
            sx={{
              marginLeft: "30px",
              "@media (max-width: 600px)": {
                marginLeft: "0px",
                marginTop: "30px",
              },
            }}
          >
            <div className="secondarytheme joinnow">
              <HashLink to="https://app.onboardix.com">
                <Button sx={{ padding: "7px 30px 7px 30px" }}>
                  {props.btnText}
                </Button>
              </HashLink>
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CtaComp;
