import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  IconButton,
} from "@mui/material";
import HeaderStyle from "../../styles/HeaderStyle.css";
import { NavLink } from "react-router-dom";
import theme from "../../pages/theme";
import { ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";
import Logo from "../../images/logo.jpg";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  //handle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //menu drawer
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
      }}
    >
      <Box
        sx={{ bgcolor: "#5e44af", paddingTop: "10px", paddingBottom: "5px" }}
      >
        <img loading="lazy" src={Logo} alt="logo" height={"30"} width="135" />
      </Box>
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <HashLink activeclassname="active" to="/#home">
            Home
          </HashLink>
        </li>
        <li>
          <HashLink to="/#aibot">Solutions</HashLink>
        </li>
        <li>
          <HashLink to="/#pricing">Pricing</HashLink>
        </li>
        <li>
          <HashLink to="/#blog">Blog</HashLink>
        </li>
        <li>
          <HashLink to="/#about">About us</HashLink>
        </li>
        <li>
          <HashLink to="/#contact">Contact us</HashLink>
        </li>
      </ul>
    </Box>
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box>
          <div>
            <br />
            <AppBar
              position="fixed"
              className="appbar"
              component="nav"
              elevation={0}
            >
              <Toolbar>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  sx={{
                    mr: 2,
                    display: { md: "none" },
                    position: "absolute",
                    right: 0,
                    paddingRight: "0.5%",
                  }}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon sx={{ color: "white" }} />
                </IconButton>

                <Box
                  sx={{
                    paddingLeft: "42px",
                    "@media (max-width: 600px)": {
                      paddingLeft: "0px",
                    },
                  }}
                >
                  <HashLink to="/#home">
                    <img
                      loading="lazy"
                      src={Logo}
                      alt="logo"
                      height="47"
                      width="230"
                    />
                  </HashLink>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", md: "block" },
                    position: "absolute",
                    right: 0,
                    paddingRight: "3.78%",
                  }}
                >
                  <ul className="navigation-menu">
                    <li>
                      <HashLink activeclassname="active" to="/#home">
                        Home
                      </HashLink>
                    </li>
                    <li>
                      <HashLink to="/#aibot">Solutions</HashLink>
                    </li>
                    <li>
                      <HashLink to="/#pricing">Pricing</HashLink>
                    </li>
                    <li>
                      <HashLink to="/#blog">Blog</HashLink>
                    </li>
                    <li>
                      <HashLink to="/#about">About us</HashLink>
                    </li>
                    <li>
                      <HashLink to="/#contact">Contact us</HashLink>
                    </li>
                  </ul>
                </Box>
              </Toolbar>
            </AppBar>
            <Box component="nav">
              <Drawer
                variant="temporary"
                open={mobileOpen}
                anchor="right"
                onClose={handleDrawerToggle}
                sx={{
                  display: { xs: "block", md: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: "200px",
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default Header;
