import React from "react";
import HeadingText from "../components/HeadingText";
import { Box, Grid } from "@mui/material";
import About1 from "../images/about1.svg";
import About2 from "../images/about2.svg";

const About = () => {
  return (
    <div>
      <HeadingText title="About Us" />
      <Box
        sx={{
          marginTop: "70px",
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, sm: 3, md: 3, lg: 6 }}
          columns={{ xs: 1, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item xs={1} sm={6} md={6} lg={6}>
            <h2>Empowering Success Through Seamless Onboarding Excellence</h2>
            <div className="about1">
              <Box
                sx={{
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
              >
                <img loading="lazy" src={About1} alt="About us" />
              </Box>
            </div>
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Welcome to the quirky and innovative world of Onboardix! We're not
              your run-of-the-mill onboarding solution; we're the cool cats of
              the HR world. So, grab a comfy chair and a cup of your favorite
              beverage, and let us regale you with our tale. <br />
              <br />
              Picture this: a bunch of HR enthusiasts decided that the
              traditional onboarding process needed a makeover. Why? Because
              watching new employees navigate through a maze of confusion was
              like witnessing a kitten trying to untangle a ball of yarn – cute,
              but oh-so-chaotic!
            </Box>
          </Grid>
          <Grid item xs={1} sm={6} md={6} lg={6}>
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              Enter Onboardix, our brainchild that brings clarity, simplicity,
              and a touch of magic to onboarding. We set out on a mission to
              make the process as smooth as your grandma's apple pie, minus the
              calories. HR wizards use our platform to conjure up customized
              workflows, guiding newbies with step-by-step instructions that are
              easier to follow than a recipe for instant noodles.
              <br />
              <br />
              But wait, there's more! We've enlisted the help of our friendly AI
              chatbot, whose wit is matched only by its intelligence. This
              digital sidekick is like having a sassy friend who knows
              everything about the company – minus the drama. Employees can ask
              questions, seek guidance, or just have a virtual chat, making the
              onboarding journey feel less like paperwork and more like a breezy
              conversation.
              <br />
              <br />
              So, whether you're an HR guru looking to dazzle your new recruits
              or an employee ready to embark on an adventure without getting
              lost in the woods, Onboardix is here to sprinkle some HR magic. We
              believe that onboarding should be as exciting as a surprise party
              and as seamless as streaming your favorite show. Join us in this
              journey to redefine onboarding, one chuckle at a time! <br />
            </Box>
            <div className="about2">
              <Box
                sx={{
                  marginTop: "50px",
                }}
              >
                <img loading="lazy" src={About2} alt="About us" />
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default About;
