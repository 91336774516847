import React from "react";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../pages/theme";

const ServiceCard = (props) => {
  const { icon: Icon } = props;
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box id="servicebox">
          <div className="circle">
            <Icon id="serviceicon" sx={{ color: "secondary.main" }} />
          </div>
          <div className="textstyle">{props.title}</div>
          <div className="desctext">
            {" "}
            <p>{props.desc}</p>
          </div>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default ServiceCard;
