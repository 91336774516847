import React from "react";
import Layout from "../components/Layout/Layout";
import { Box } from "@mui/material";
import MainBanner from "../components/MainBanner";
import HomeStyle from "../styles/HomeStyle.css";
import Services from "../components/Services";
import About from "../components/About";
import ClientScroll from "../components/ClientsScroll";
import SiteStats from "../components/SiteStats";
import CallToAction from "../components/CallToAction";
import Testimonial from "../components/TestimonialList";
import PricingList from "../components/PricingList";
import FAQList from "../components/FAQList";
import BlogsList from "../components/BlogsList";
import Contact from "../components/Contact";
import FeatureExplainer from "../components/FeatureExplainer";
import DashboardImg from "../components/DashboardImg";
import UserDash from "../components/UserDash";
import CtaComp from "../components/CtaComp";
import VideoComp from "../components/VideoComp";

const Home = () => {
  return (
    <>
      <Layout>
        <div>
          <Box id="home">
            <MainBanner />
          </Box>

          {/* <Box
            sx={{
              marginTop: "30px",
              paddingLeft: "70px",
              paddingRight: "72px",
              "@media (max-width: 600px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <ClientScroll />
          </Box> */}

          <Box
            sx={{
              marginTop: "100px",
              "@media (max-width: 600px)": {
                marginTop: "60px",
              },
            }}
          >
            <VideoComp />
          </Box>

          <Box
            sx={{
              marginTop: "100px",
            }}
          >
            <DashboardImg />
          </Box>

          <Box
            sx={{
              marginTop: "80px",
            }}
          >
            <UserDash />
          </Box>

          <Box
            sx={{
              marginTop: "100px",
              marginBottom: "100px",
              "@media (max-width: 900px)": {
                marginTop: "50px",
              },
            }}
          >
            <CtaComp
              message="Revolutionize onboarding for employees, clients, and vendors with Onboardix"
              btnText="Begin Transformation!"
            />
          </Box>

          <Box
            id="aibot"
            sx={{
              marginTop: "30px",
            }}
          >
            <FeatureExplainer />
          </Box>

          <Box
            id="services"
            sx={{
              marginTop: "55px",
            }}
          >
            <Services />
          </Box>

          <Box
            sx={{
              marginTop: "100px",
              marginBottom: "100px",
              "@media (max-width: 900px)": {
                marginTop: "50px",
              },
            }}
          >
            <CtaComp
              message="Experience seamless onboarding for all stakeholders - Explore Onboardix features today!"
              btnText="Explore Features!"
            />
          </Box>

          {/* <Box
            sx={{
              marginTop: "80px",
              paddingLeft: "70px",
              paddingRight: "72px",
              "@media (max-width: 600px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <SiteStats />
          </Box> */}

          {/* <Box
            sx={{
              marginTop: "80px",
            }}
          >
            <Testimonial />
          </Box> */}
          <Box
            sx={{
              marginTop: "80px",
              paddingLeft: "70px",
              paddingRight: "72px",
              "@media (max-width: 600px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <CallToAction />
          </Box>

          <Box
            id="pricing"
            sx={{
              marginTop: "80px",
            }}
          >
            <PricingList />
          </Box>
          <Box
            sx={{
              marginTop: "80px",
              paddingLeft: "70px",
              paddingRight: "72px",
              "@media (max-width: 600px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <FAQList />
          </Box>

          <Box
            sx={{
              marginTop: "100px",
              marginBottom: "100px",
              "@media (max-width: 900px)": {
                marginTop: "50px",
              },
            }}
          >
            <CtaComp
              message="Optimize onboarding efficiency across your organization - Discover Onboardix now!"
              btnText="Discover Now!"
            />
          </Box>

          <Box
            id="blog"
            sx={{
              marginTop: "60px",
            }}
          >
            <BlogsList />
          </Box>
          <Box
            id="about"
            sx={{
              marginTop: "80px",
              paddingLeft: "70px",
              paddingRight: "72px",
              "@media (max-width: 600px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <About />
          </Box>
          <Box
            id="contact"
            sx={{
              marginTop: "70px",
              paddingLeft: "70px",
              paddingRight: "72px",
              "@media (max-width: 600px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
          >
            <Contact />
          </Box>

          <Box
            sx={{
              marginTop: "100px",
              marginBottom: "150px",
              "@media (max-width: 900px)": {
                marginTop: "50px",
              },
            }}
          >
            <CtaComp
              message="Ready to simplify onboarding for employees, clients, and vendors? Explore Onboardix - Click here!"
              btnText="Explore Onboardix!"
            />
          </Box>
        </div>
      </Layout>
    </>
  );
};

export default Home;
