import React from "react";
import { Box } from "@mui/material";
const Checkout = () => {
  return (
    <div>
      <Box>Checkout</Box>
    </div>
  );
};

export default Checkout;
