import React from "react";
import Layout from "../components/Layout/Layout";
import { Box, Grid, TextField, Button, Snackbar } from "@mui/material";
import Blog from "../components/Blog";
import BlogImage3 from "../images/blog-3.jpg";
import BlogImg1 from "../images/blog-1.jpg";
import BlogImg2 from "../images/blog-2.jpg";
import AuthorImg from "../images/author.jpg";
import { HashLink } from "react-router-hash-link";
import OffboardingMeans from "../images/offboarding-means.jpg";
import WorkforceRetentionImg from "../images/workforce-retention.jpg";
import OnboardingCareersImg from "../images/onboarding-careers.jpg";
import OnboardingQuestionnaireImg from "../images/onboarding-questionnaire.jpg";
import BadCultureImg from "../images/bad-workplace-culture.jpg";
import OnboardingandOffboardingImg from "../images/onboardingandoffboarding.jpg";
import RecruitmentManagementSystemsImg from "../images/recruitment-management-systems.jpg";
import RecruitmentAgenciesImg from "../images/recruitment-agencies-for-marketing.jpg";

const AllArticles = () => {
  return (
    <>
      <Layout>
        <Box
          sx={{
            marginTop: "150px",
            marginLeft: "80px",
            marginRight: "80px",
            "@media (max-width: 900px)": {
              paddingBottom: "40px",
            },
            "@media (max-width: 600px)": {
              marginLeft: "20px",
              marginRight: "20px",
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3, lg: 4 }}
            columns={{ xs: 1, sm: 1, md: 1, lg: 18 }}
          >
            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/psychology-of-successful-onboarding-and-offboarding">
                <Blog
                  category="HR Insights"
                  title="The Psychology of Successful Onboarding and Offboarding"
                  image={BlogImg1}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 9, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/saas-solutions-for-sme-onboarding-success">
                <Blog
                  category="HR Insights"
                  title="SaaS Solutions for SME Onboarding Success : HR Insights"
                  image={BlogImage3}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 8, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/onboarding-best-practices">
                <Blog
                  category="Best Practices"
                  title="Onboarding Best Practices: Empowering HRs for Success"
                  image={BlogImg2}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="June 17, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/what-offboarding-means">
                <Blog
                  category="Offboarding"
                  title="What offboarding means ? The Hidden Key to Business Growth"
                  image={OffboardingMeans}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 16, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/ultimate-guide-to-workforce-retention">
                <Blog
                  category="Retention"
                  title="How To Retain Employees Like The Fortune 100 : The Ultimate Guide To
                  Workforce Retention"
                  image={WorkforceRetentionImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 17, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/onboarding-careers-a-secret-sauce">
                <Blog
                  category="HR Insights"
                  title="Onboarding Careers: Your Secret Sauce for SME Success"
                  image={OnboardingCareersImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 19, 2023"
                />
              </HashLink>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/onboarding-questionnaires-in-smes">
                <Blog
                  category="HR Insights"
                  title="Boosting Employee Engagement with Onboarding Questionnaire in SMEs"
                  image={OnboardingQuestionnaireImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 29, 2023"
                />
              </HashLink>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/bad-workplace-culture">
                <Blog
                  category="HR Insights"
                  title="5 Signs of a Bad Workplace Culture and How SMEs Can Fix It"
                  image={BadCultureImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Oct 1, 2023"
                />
              </HashLink>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/onboarding-offboarding-strategies">
                <Blog
                  category="HR Insights"
                  title="On boarding and Off boarding Strategies: Navigating SME Challenges"
                  image={OnboardingandOffboardingImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Oct 3, 2023"
                />
              </HashLink>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/recruitment-management-systems">
                <Blog
                  category="HR Insights"
                  title="The Future of Onboarding: How AI is Revolutionizing SME Recruitment
                  Management Systems"
                  image={RecruitmentManagementSystemsImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Oct 5, 2023"
                />
              </HashLink>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={6}>
              <HashLink to="/blogs/recruitment-agencies-for-marketing">
                <Blog
                  category="HR Insights"
                  title="Revolutionizing SME Growth: Recruitment Agencies for Marketing and
                  Onboarding SaaS"
                  image={RecruitmentAgenciesImg}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Oct 8, 2023"
                />
              </HashLink>
            </Grid>
          </Grid>
        </Box>
      </Layout>
    </>
  );
};

export default AllArticles;
