import React from "react";
import { Box } from "@mui/material";
import theme from "../pages/theme";
import { ThemeProvider } from "@mui/material/styles";

const HeadingText = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box
          sx={{
            fontWeight: 700,
            textAlign: "center",
            justifyContent: "center",
            color: "#242c31",
            "@media (max-width: 600px)": {
              fontSize: "20px",
              color: "#242c31",
            },
          }}
        >
          <Box
            sx={{
              fontSize: "20px",
              "@media (max-width: 600px)": {
                fontSize: "16px",
              },
            }}
          >
            {props.title}
          </Box>
        </Box>
        <Box>
          <hr
            style={{
              marginTop: "10px",
              backgroundColor: "#5e44af",
              height: 4,
              width: "60px",
              border: "none",
              borderRadius: "3px",
            }}
          />
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default HeadingText;
