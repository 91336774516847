import React, { useState } from "react";
import BlogStyles from "../styles/BlogStyles.css";
import {
  Box,
  Grid,
  Divider,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import Layout from "../components/Layout/Layout";
import theme from "../pages/theme";
import { ThemeProvider } from "@mui/material/styles";
import { HashLink } from "react-router-hash-link";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import BlogImage3 from "../images/blog-3.jpg";
import BlogImg1 from "../images/blog-1.jpg";
import BlogImg2 from "../images/offboarding-means.jpg";
import AuthorImg from "../images/author.jpg";
import Blog from "./Blog";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

const BlogComp = ({
  title,
  content,
  author,
  date,
  image,
  imgalt,
  children,
}) => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("This field cannot be empty")
      .email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const signUp = async (data) => {
    setError(false);
    setSuccess(false);
    setLoader(true);
    await axios
      .post(`https://backend.onboardix.com/api/subscribe?email=${data.email}`)
      .then((res) => {
        setLoader(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoader(false);
        setError(true);
      });
  };

  return (
    <Layout>
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          columns={{ xs: 1, sm: 1, md: 14, lg: 22 }}
        >
          <Grid item xs={1} sm={1} md={9} lg={17}>
            <div className="blog">
              <h1 className="blog-title">{title}</h1>
              <img src={image} alt={imgalt}></img>
              <br />
              <br />
              <div> {children} </div>
              <Box sx={{ marginTop: "40px" }}>
                <Divider />
                <Divider />
              </Box>
              <Box sx={{ marginTop: "10px" }}>
                <div className="blog-details">
                  <p className="blog-author">By {author}</p>
                  <p>CEO and Founder at Onboardix</p>
                  <p className="blog-date">{date}</p>
                </div>
              </Box>
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={5} lg={5}>
            <div id="sticky">
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "primary.main",
                }}
              >
                What is Onboardix?
              </Box>
              <Box
                sx={{
                  paddingTop: "10px",
                  fontSize: "15px",
                  paddingBottom: "5px",
                }}
              >
                <p>
                  Onboardix revolutionizes the onboarding process by offering a
                  seamless experience for both HR professionals and new
                  employees.{" "}
                </p>
                <p>
                  HR professionals create tailored workflows using the platform,
                  guiding employees through their onboarding journey with
                  step-by-step instructions and document access.{" "}
                </p>{" "}
                <p>
                  Employees access their personalized timeline and complete
                  tasks while benefiting from an AI-powered chatbot for instant
                  assistance.{" "}
                </p>
                <p>
                  This centralized approach ensures clarity, engagement, and
                  efficient onboarding, setting the stage for a positive and
                  productive employee experience.
                </p>
              </Box>
              <Divider></Divider>
              <Divider></Divider>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "primary.main",
                  paddingTop: "15px",
                }}
              >
                Our solutions
              </Box>
              <Box
                sx={{
                  fontSize: "15px",
                  paddingBottom: "5px",
                }}
              >
                <ul>
                  <li>Streamlined Onboarding Workflows</li>
                  <li>Interactive Employee Timelines</li>
                  <li>ChatGPT Integrated bot for instant help</li>
                  <li>Bulk Onboarding Efficiency</li>
                  <li>Comprehensive Reporting and Analytics</li>
                  <li>Automated Reminders for employees</li>
                  <li>Personalized Portal for Admins/HRs</li>
                  <li>Personalized Portal for Employees</li>
                </ul>
              </Box>
              <Divider></Divider>
              <Divider></Divider>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "primary.main",
                  paddingTop: "15px",
                }}
              >
                Subscribe for Our Newsletter
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Your Email ID"
                  fullWidth
                  {...register("email")}
                  error={errors.email ? true : false}
                  helperText={errors.email?.message}
                />
                {success && (
                  <Box
                    sx={{ fontSize: "12px", marginTop: "10px", color: "green" }}
                  >
                    Subscribed Successfully!
                  </Box>
                )}
                {error && (
                  <Box
                    sx={{ fontSize: "12px", marginTop: "10px", color: "red" }}
                  >
                    Something went wrong, please try again later.
                  </Box>
                )}
                <Box sx={{ m: 1, position: "relative" }}>
                  <Button
                    onClick={handleSubmit(signUp)}
                    disabled={loader}
                    sx={{
                      backgroundColor: "#5e44af",
                      color: "white",
                      fontSize: "13px",
                      fontWeight: "bold",
                      padding: "8px 25px",
                      textTransform: "none",
                      fontFamily: "Montserrat",
                      marginTop: "10px",
                      "&:hover": { color: "#5e44af" },
                    }}
                  >
                    Subscribe
                  </Button>
                  {loader && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#e0e0e0",
                        position: "absolute",
                        top: "60%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
        <Box sx={{ margin: "30px 86px 0 67px" }}>
          <Box sx={{ marginBottom: "20px" }}>
            Explore Our Best-Performing Blogs:
          </Box>
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
            columns={{ xs: 1, sm: 1, md: 1, lg: 12 }}
          >
            <Grid item xs={1} sm={1} md={1} lg={4}>
              <HashLink to="/blogs/psychology-of-successful-onboarding-and-offboarding">
                <Blog
                  category="HR Insights"
                  title="The Psychology of Successful Onboarding and Offboarding"
                  image={BlogImg1}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 9, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4}>
              <HashLink to="/blogs/what-offboarding-means">
                <Blog
                  category="Offboarding"
                  title="What offboarding means ? The Hidden Key to Business Growth"
                  image={BlogImg2}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 16, 2023"
                />
              </HashLink>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4}>
              <HashLink to="/blogs/saas-solutions-for-sme-onboarding-success">
                <Blog
                  category="Insights"
                  title="SaaS Solutions for SME Onboarding Success : HR Insights"
                  image={BlogImage3}
                  avatar={AuthorImg}
                  name="Ashpak Shaikh"
                  date="Sept 8, 2023"
                />
              </HashLink>
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              float: "right",
            }}
          >
            <Button
              onClick={() => {
                navigate(`/explore-articles`);
              }}
              sx={{
                textTransform: "none",
                cursor: "pointer",
                height: "53px",
                width: "220px",
                fontSize: "15px",
              }}
            >
              Explore More Articles <EastIcon sx={{ marginLeft: "10px" }} />
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    </Layout>
  );
};

export default BlogComp;
