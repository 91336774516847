import React, { useState, useRef } from "react";
import {
  Box,
  Grid,
  Divider,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import Layout from "../components/Layout/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

const Survey = () => {
  const [question, setQuestion] = useState(
    "How do you currently handle the onboarding process in your organization, and what pain points or challenges have you encountered with it?"
  );
  const [qnum, setQNum] = useState(1);
  const form = useRef();

  const questions = [
    {
      num: 1,
      q:
        "How do you currently handle the onboarding process in your organization, and what pain points or challenges have you encountered with it?",
    },
    {
      num: 2,
      q:
        "Can you describe the typical steps and workflows involved in your onboarding process for different roles within your organization?",
    },
    {
      num: 3,
      q:
        "What are the common issues or bottlenecks you face when onboarding multiple employees simultaneously?",
    },
    {
      num: 4,
      q:
        "How do you track and manage the progress of new employees during their onboarding journey? Are there any specific metrics or KPIs you monitor?",
    },
    {
      num: 5,
      q:
        "How do you currently communicate important information and next steps to new employees during onboarding?",
    },
    {
      num: 6,
      q:
        "What kind of reports or analytics would be most valuable to you in managing the onboarding process more effectively?",
    },
    {
      num: 7,
      q:
        "Have you ever faced challenges in responding to candidates or new employees' queries promptly? How do you handle these situations currently?",
    },
    {
      num: 8,
      q:
        "What additional features or functionalities would you find helpful in streamlining the onboarding process and improving HR efficiency?",
    },
    {
      num: 9,
      q:
        "Can you share any specific use cases or scenarios where you think a chatbot could be beneficial in assisting candidates or employees during onboarding?",
    },
    {
      num: 10,
      q:
        "Are there any security or data privacy concerns you have regarding the use of a SaaS tool like Onboardix, and how do you expect those concerns to be addressed?",
    },
  ];

  const submitAns = async (data) => {
    setQNum(qnum + 1);
    setQuestion(questions[qnum].q);
    writeAns(data);
    form.current.reset();
  };

  const writeAns = async (data) => {
    const newData = {
      qnum: qnum,
      answer: data.answer,
    };
    await axios
      .post(`https://backend.onboardix.com/api/submitanswer`, newData)
      .then((res) => {})
      .catch((error) => {});
    setQNum(qnum + 1);
  };

  const validationSchema = Yup.object().shape({
    answer: Yup.string().required("This field cannot be empty"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <Layout>
        <Box
          sx={{
            margin: "190px 350px 30px 350px",
            "@media (max-width: 900px)": {
              margin: "100px 20px 30px 20px",
            },
          }}
        >
          <Box sx={{ fontSize: "25px", fontWeight: "700" }}>Survey</Box>
          <Box sx={{ marginTop: "20px" }}>
            Your input is important to us, and we anticipate that the survey
            will take approximately 3 to 5 minutes to finish. We would
            appreciate it if you could take a moment to respond to the following
            questions. This will help us gain a better understanding of your
            onboarding process.
          </Box>

          <Box sx={{ marginTop: "40px" }}>
            {qnum < 11 && (
              <form ref={form}>
                <Box sx={{ marginTop: "30px" }}>Question {qnum}</Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    height: "50px",
                    "@media (max-width: 900px)": {
                      height: "90px",
                    },
                  }}
                >
                  {question}
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <TextField
                    required
                    id="answer"
                    name="answer"
                    label="Your Answer"
                    multiline
                    rows={4}
                    fullWidth
                    {...register("answer")}
                    error={errors.answer ? true : false}
                    helperText={errors.answer?.message}
                  />
                </Box>
              </form>
            )}
            {qnum > 10 && (
              <Box>
                <Box
                  sx={{
                    marginTop: "30px",
                    fontWeight: "700",
                    fontSize: "20px",
                  }}
                >
                  Thank you!
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  We are grateful for your time and effort in completing the
                  survey.
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  Discover the opportunity to enhance your onboarding process at
                  no cost! <a href="https://app.onboardix.com/">Register now</a>{" "}
                  to gain free access to Onboardix. Act quickly, as this is a
                  limited-time offer.
                </Box>
              </Box>
            )}
            <Box
              sx={{
                marginTop: "30px",
                paddingBottom: "85px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box></Box>
              {qnum < 10 && (
                <Box>
                  <Button
                    onClick={handleSubmit(submitAns)}
                    sx={{
                      bgcolor: "#5e44af",
                      textTransform: "none",
                      color: "#fff",
                      borderRadius: "10px",
                      width: "160px",
                      fontWeight: "600",
                      "&:hover": {
                        bgcolor: "#5e44af",
                      },
                    }}
                  >
                    Next Question
                  </Button>
                </Box>
              )}
              {qnum > 9 && (
                <Box>
                  <Button
                    onClick={handleSubmit(writeAns)}
                    disabled={qnum > 10}
                    sx={{
                      bgcolor: "#5e44af",
                      textTransform: "none",
                      color: "#fff",
                      borderRadius: "10px",
                      width: "160px",
                      fontWeight: "600",
                      "&:hover": {
                        bgcolor: "#5e44af",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default Survey;
